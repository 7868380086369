import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Canvas } from '@react-three/fiber'
import { OrthographicCamera } from '@react-three/drei'
import { useRecoilValue } from 'recoil'
import { bgDarkenedState, readyState, projectsState } from '@/store'
import { usePrevious } from '@/hooks/usePrevious'
import SceneModel from './resources/SceneModel.ts'
import MouseMoveController from './resources/MouseMoveController.ts'
import Effects from './resources/Effects'
import { Mesh } from './resources/Mesh'
// import SceneConfig from './resources/SceneConfig.ts'
import { useTransition } from './Background.gsap'
import * as styles from './Background.styles.scss'

const IS_BROWSER = typeof window !== 'undefined'

if (IS_BROWSER) {
  MouseMoveController.start()
}

const Background = ({ colors }) => {
  const projectIndex = useRecoilValue(projectsState)
  const prevIndex = usePrevious(projectIndex) || 0
  const ref = useRef()
  const isDarkened = useRecoilValue(bgDarkenedState)
  const isReady = useRecoilValue(readyState)

  useTransition(ref, {
    isDarkened,
    isVisible: isReady,
    selectors: {
      darken: `.${styles.darken}`,
    },
  })

  useEffect(() => {
    if (projectIndex !== prevIndex) {
      let direction = projectIndex < prevIndex ? -1 : 1

      // if wrapping around full pattern set, then inverse travel direction (as going from start to end of set or vice versa)
      if (Math.abs(projectIndex - prevIndex) > 1) {
        direction *= -1
      }

      SceneModel.scrollDirection = direction
      SceneModel.scrollIndex += direction
    }
    // SceneConfig.SECTION_COLORS = colors // Use hardcoded colors for now
  }, [projectIndex, colors])

  const pixelRatio = Math.min(2, window.devicePixelRatio || 1)

  return (
    <div className={styles.Background} ref={ref}>
      <div className={styles.darken} />
      <Canvas
        dpr={pixelRatio}
        // camera={{
        //   // zoom: 1,
        //   left: -0.5,
        //   right: 0.5,
        //   top: 0.5,
        //   bottom: -0.5,
        //   position: [0, 0, 1],
        //   near: 0,
        //   far: 2,
        // }}
        // orthographic
        updateDefaultCamera={false}
      >
        <OrthographicCamera
          makeDefault
          manual={true}
          zoom={1}
          top={-0.5}
          bottom={0.5}
          left={0.5}
          right={-0.5}
          near={0}
          far={2}
          position={[0, 0, 1]}
        />
        {/* TEST <color attach="background" args={["blue"]} /> */}
        <Mesh />
        <Effects />
      </Canvas>
    </div>
  )
}

Background.defaultProps = {
  colors: [
    [
      [1, 0, 0], // red
      [1, 0, 1], // purple
    ],
  ],
}

Background.propTypes = {
  colors: PropTypes.array,
}

export { Background }
