import React from 'react'
import PropTypes from 'prop-types'
import Obfuscate from 'react-obfuscate'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({
  to,
  className,
  target,
  children,
  onClick,
  download,
  activeState,
  activeClass,
  ...attributes
}) => {
  // This assumes internal links will start with exactly one slash,
  // and that anything else is external.
  const isInternalPath = to ? /^\/(?!\/)/.test(to) : false

  // Gatsby link active check
  const isActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent ? { className: activeClass } : null
  }

  // Check if link is internal
  if (isInternalPath) {
    return (
      <GatsbyLink
        to={to}
        className={className}
        onClick={onClick}
        getProps={activeState ? isActive : undefined}
        {...attributes}
      >
        {children}
      </GatsbyLink>
    )
  }

  // Check if link starts with mailto:
  const isEmail = to ? /^((mailto:))/.test(to) : false

  if (isEmail) {
    return (
      <Obfuscate email={to.substring(7)} className={className}>
        {children}
      </Obfuscate>
    )
  }

  // Check if extenal link
  if (to) {
    let relOutput = ''
    let targetOutput = ''
    if (target === '_blank') {
      targetOutput = '_blank'
      relOutput = 'noopener noreferrer'
    }

    return (
      <a
        href={to}
        className={className}
        target={targetOutput}
        rel={relOutput}
        download={download}
        {...attributes}
      >
        {children}
      </a>
    )
  }

  if (onClick) {
    const handleKeyDown = (e) => {
      if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
        e.preventDefault()
        onClick(e)
      }
    }

    return (
      <span
        role="button"
        className={className}
        tabIndex="0"
        onClick={onClick}
        onKeyDown={handleKeyDown}
        {...attributes}
      >
        {children}
      </span>
    )
  }

  // Output wrapper element if className is defined
  if (className) {
    return <span className={className}>{children}</span>
  }

  return children
}

Link.defaultProps = {
  activeClass: 'is-active',
}

Link.propTypes = {
  to: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  download: PropTypes.bool,
  activeState: PropTypes.bool,
  activeClass: PropTypes.string,
}

export { Link }
