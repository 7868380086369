import { atom } from 'recoil'

/**
 * Store CMS preview state here to display messaging etc
 *
 * @usage
 * const isPreview = useRecoilValue(previewState)
 */

export const previewState = atom({
  key: 'previewState', // unique ID (with respect to other atoms/selectors)
  default: false,
})
