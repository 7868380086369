import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import { mutedState } from '@/store'
import { AudioIcon } from '@/components/AudioIcon'
import * as styles from './BtnAudioToggle.styles.scss'

const BtnAudioToggle = () => {
  const [isMuted, setIsMuted] = useRecoilState(mutedState)
  const [isHovering, setIsHovering] = useState(false)

  const handleOnClick = () => setIsMuted((prev) => !prev)
  const handleMouseOver = () => setIsHovering(true)
  const handleMouseOut = () => setIsHovering(false)

  return (
    <button
      type="button"
      onClick={handleOnClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onFocus={handleMouseOver}
      onBlur={handleMouseOut}
      className={styles.BtnAudioToggle}
      aria-label="Toggle Audio"
      data-muted={isMuted}
    >
      <AudioIcon size="small" isMuted={isMuted} isActive={isHovering} />
    </button>
  )
}

export { BtnAudioToggle }
