/**
 * @author alteredq / http://alteredqualia.com/
 */

import { ShaderMaterial, UniformsUtils } from 'three'
import { Pass, FullScreenQuad } from 'three/examples/jsm/postprocessing/Pass'
import { RadialBlurShader } from './RadialBlurShader'

const RadialBlurPass = function (
  blurDistance,
  blurIntensity,
  originalIntensity,
  centerDarkening,
  radialEdgeBrightnessReduction
) {
  // Pass.call(this) // current build setup does not seem to allow calling of a parent class constructor, manually performing parent class actions below

  // if set to true, the pass is processed by the composer
  this.enabled = true;
  // if set to true, the pass indicates to swap read and write buffer after rendering
  this.needsSwap = true;
  // if set to true, the pass clears its buffer before rendering
  this.clear = false;
  // if set to true, the result of the pass is rendered to screen. This is set automatically by EffectComposer.
  this.renderToScreen = false;


  if (RadialBlurShader === undefined)
    console.error('RadialBlurPass relies on RadialBlurShader')

  const shader = RadialBlurShader

  this.uniforms = UniformsUtils.clone(shader.uniforms)

  this.material = new ShaderMaterial({
    uniforms: this.uniforms,
    vertexShader: shader.vertexShader,
    fragmentShader: shader.fragmentShader,
  })

  if (blurDistance !== undefined)
    this.uniforms.blurDistance.value = blurDistance
  if (blurIntensity !== undefined)
    this.uniforms.blurIntensity.value = blurIntensity
  if (originalIntensity !== undefined)
    this.uniforms.originalIntensity.value = originalIntensity
  if (radialEdgeBrightnessReduction !== undefined)
    this.uniforms.radialEdgeBrightnessReduction.value =
      radialEdgeBrightnessReduction
  if (centerDarkening !== undefined)
    this.uniforms.centerDarkening.value = centerDarkening

  this.fsQuad = new FullScreenQuad(this.material)
}

RadialBlurPass.prototype = Object.assign(Object.create(Pass.prototype), {
  constructor: RadialBlurPass,

  render(renderer, writeBuffer, readBuffer /* , deltaTime , maskActive */) {
    this.uniforms.tDiffuse.value = readBuffer.texture

    if (this.renderToScreen) {
      renderer.setRenderTarget(null)
      this.fsQuad.render(renderer)
    } else {
      renderer.setRenderTarget(writeBuffer)
      if (this.clear) renderer.clear()
      this.fsQuad.render(renderer)
    }
  },
})

export { RadialBlurPass }
export default RadialBlurPass
