const { storyblokConfig } = require('../storyblok-config')

/**
 * Link Resolver for Storyblok content
 *
 * @param {object} page
 */

exports.linkResolver = (path) => {
  if (path === storyblokConfig.homeSlug) {
    return `/`
  }

  // fallback route
  return `/${path}`
}
