import { useEffect } from 'react'
import { gsap, Power1 } from 'gsap'

export const useTransition = (ref, { isOpen, selectors }) => {
  const { bg, content } = selectors

  useEffect(() => {
    const $root = ref.current
    const $bg = $root.querySelector(bg)
    const $content = $root.querySelector(content)

    let tl

    if (isOpen) {
      tl = gsap.timeline({ defaults: { ease: Power1.easeOut } })
      tl.to($bg, { opacity: 1, duration: 0.5 })
      tl.to($content, { opacity: 1, duration: 0.5 }, 0)
    } else {
      tl = gsap.timeline({
        defaults: { ease: Power1.easeOut, overwrite: true },
      })
      tl.to($bg, { opacity: 0, duration: 0.5 })
      tl.to($content, { opacity: 0, duration: 0.5 }, 0)
    }

    return () => {
      if (tl) tl.kill()
    }
  }, [ref, isOpen, bg, content])
}
