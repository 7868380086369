/**
 * preloadAudio: sort an array of objects by key
 * @source https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore
 * @param {Array} audioFiles
 * @param {Function} onAllLoaded
 */

const preloadAudio = (audioFiles = [], onAllLoaded = () => {}) => {
  let loaded = 0

  const onLoaded = (event) => {
    loaded += 1
    console.log(`loaded audio: ${loaded}/${audioFiles.length}`) // eslint-disable-line no-console
    event.srcElement.removeEventListener('canplaythrough', onLoaded, false)
    event.srcElement.removeEventListener('error', onError, false)
    if (loaded === audioFiles.length) {
      // all have loaded, callback
      onAllLoaded()
    }
  }

  const onError = () => {
    loaded += 1
    console.warn(`error loading audio: ${loaded}/${audioFiles.length}`) // eslint-disable-line no-console
  }

  const loadAudio = (url) => {
    const audio = new Audio()
    // once this file loads, it will call loadedAudio()
    // the file will be kept by the browser as cache
    audio.addEventListener('canplaythrough', onLoaded, false)
    audio.addEventListener('error', onError)
    audio.muted = true
    audio.preload = 'auto'
    audio.src = url
    setTimeout(() => {
      audio.load() // https://stackoverflow.com/a/49794011
    }, 400)
  }

  audioFiles.forEach((file) => {
    if (file !== undefined) {
      loadAudio(file)
    }
  })
}

exports.preloadAudio = preloadAudio
