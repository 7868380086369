export default class SceneConfig {
  public static readonly EFFECT_CHLADNI: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('chladni') !== -1)
  public static readonly EFFECT_MESH: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('mesheffect') !== -1)
  public static readonly EFFECT_MESH_V1: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('mesheffect1') !== -1)
  public static readonly EFFECT_MESH_V2: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('mesheffect2') !== -1)
  public static readonly EFFECT_MESH_V3: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('mesheffect3') !== -1)
  public static readonly EFFECT_MESH_V4: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('mesheffect4') !== -1)
  public static readonly EFFECT_RINGS: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('rings') !== -1)
  public static readonly EFFECT_RINGS_V1: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('rings1') !== -1)
  public static readonly EFFECT_V1: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('v1') !== -1)
  public static readonly EFFECT_V2: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('v2') !== -1)
  public static readonly EFFECT_V3: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('v3') !== -1)
  public static readonly EFFECT_V4: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('v4') !== -1)
  public static readonly EFFECT_V5: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('v5') !== -1)
  public static readonly EFFECT_V6: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('v6') !== -1)
  public static readonly EFFECT_NO_LINES: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('noline') !== -1)
  public static readonly EFFECT_NO_LINES_V1: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('noline1') !== -1)

  public static readonly NO_PERTURB: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('noperturb') !== -1)

  // ?all query string to allow browsing of pre configured patterns
  public static readonly ALL_FORMS: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('all') !== -1)

  // ?select query string to allow manual browsing of patterns configurations
  public static readonly MANUAL_SELECT: boolean =
    false ||
    (typeof window !== 'undefined' &&
      window.location.search.indexOf('select') !== -1)
  // range of chladni patterns for manual ?select query
  public static readonly CHLADNI_RANGE: number = 15

  // max fps of animations
  public static readonly MAX_FPS: number = 120

  // chladni pattern scene zoom out value (including lens distortion)
  public static readonly ZOOM: number = 8 // 1.25

  // chladni pattern zoom scale value
  public static readonly PATTERN_ZOOM: number = 1.1

  // speed of chladni edge distortion
  public static readonly ANIM_SPEED: number = 0.5

  // touch interaction follow easing spring values
  public static readonly TOUCH_MOVE_ACCEL: number = 0.02
  public static readonly TOUCH_MOVE_DECEL: number = 0.73

  // minimum speed before jumping touch position location (to avoid moving from last position)
  public static readonly TOUCH_MIN_SPEED: number = 0.0002 // 0.001

  // zoom scale when moving through patterns
  public static readonly ZOOM_AMOUNT: number = 1.7

  // range of pattern shifting when moving mouse around view
  public static readonly OFFSET_SHIFT_RANGE: number = 0.01 // 0.05
  public static readonly OFFSET_SHIFT_RANGE_OFFSET: number = 0.005 // 0.02

  // transition through patterns speed easing spring values
  public static readonly TRANSITION_MOVE_ACCEL: number = 0.0018
  public static readonly TRANSITION_MOVE_DECEL: number = 0.92

  // chladni pattern shifting speed and offset amount
  public static readonly SHIFT_OFFSET_SPEED: number = 1
  public static readonly SHIFT_OFFSET_AMPLITUDE: number = 0.025

  // chladni pattern center shift offset amount and wave amplitude
  public static readonly CENTER_SHIFT_AMOUNT: number = 0.007
  public static readonly CENTER_SHIFT_AMPLITUDE: number = 0.15

  // chladni pattern offset soften easing spring values
  public static readonly OFFSET_SHIFT_MOVE_ACCEL: number = 0.01
  public static readonly OFFSET_SHIFT_MOVE_DECEL: number = 0.75

  // radial zoom blur zoom soften easing spring values
  public static readonly ZOOM_SPEED_ACCEL: number = 0.02
  public static readonly ZOOM_SPEED_DECEL: number = 0.8

  // touch intensity speed soften easing spring values
  public static readonly TOUCH_SPEED_ACCEL: number = 0.002 // 0.0075
  public static readonly TOUCH_SPEED_DECEL: number = 0.9 // 0.85

  // disortion amount soften easing spring values
  public static readonly DISORTION_AMOUNT_MULTIPLIER: number = 150 // 100
  public static readonly DISORTION_AMOUNT_ACCEL: number = 0.0075 // 0.0001 // 0.0005
  public static readonly DISORTION_AMOUNT_DECEL: number = 0.85 //0.95 //  0.999
  public static readonly DISORTION_AMOUNT_DECAY: number = 0.998 // 0.999 // 0.997

  // 3d rotation view offset angle amount
  public static readonly VIEW_OFFSET_AMOUNT: number = 0.075 // 0.2

  // 3d rotation target ease back to center amount (if absTouchSpeed <= VIEW_OFFSET_MIN_TOUCH_SPEED)
  public static readonly VIEW_OFFSET_MOVE_RESTORE: number = 0.98
  // 3d rotation offset soften easing spring values
  public static readonly VIEW_OFFSET_MOVE_ACCEL: number = 0.003
  public static readonly VIEW_OFFSET_MOVE_DECEL: number = 0.75

  // minium touch / mouse speed at which 3d rotation eases back to center
  public static readonly VIEW_OFFSET_MIN_TOUCH_SPEED: number = 0.0005

  // max distance touch can move before considered a tap
  public static readonly MAX_TAP_DIST: number = 0.1

  // max distance touch can move before considered a tap
  public static readonly MAX_TAP_DIST: number = 0.1

  // color point configuration
  // 2 points - each color determined by section index from SECTION_COLORS array below

  // color point distances from center
  public static readonly COLOR_1_DIST: number = 0.85 * 0.6 // 1.1 // * 0.3
  public static readonly COLOR_2_DIST: number = 0.85 * 0.5 // 0.6 // * 0.3

  // color point intensities
  public static readonly COLOR_1_INTENSITY: number = 0.8 // 0.9 // 1.3
  public static readonly COLOR_2_INTENSITY: number = 0.8 // 0.9 // 1.7

  // color point radii
  public static readonly COLOR_1_SIZE: number = 1 / 2.2 // 2.7 // 3 // 2.3
  public static readonly COLOR_2_SIZE: number = 1 / 1.2 // 1.8 // 2 // 1.1

  // color points base phase and speed orbiting around center
  public static readonly COLOR_REVOLVE_PHASE: number = 0
  public static readonly COLOR_REVOLVE_SPEED: number = 0.3

  // second color points phase and speed offset orbiting around center
  public static readonly COLOR_2_PHASE: number = 0.5 - 0.25 * 1.2
  public static readonly COLOR_2_SPEED: number = 1.66

  // amount to darken center of view (0 = 0% darkeneing, 1 = 100% black in center)
  public static readonly CENTER_DARKENING: number = 0.6 // 0.35

  // amount of noise (0 = 0% noise, 1 = 100% full black noise)
  public static readonly NOISE_INTENSITY: number = 0.3 // 0.12 // 0.18

  // scene glow amount
  public static readonly SCENE_GLOW_STRENGTH: number = -1 // 1.5
  // scene glow radius
  public static readonly SCENE_GLOW_RADIUS: number = 0 // 0.4
  // brightness threshold for scene glow
  public static readonly SCENE_GLOW_THRESHOLD: number = 1 // 0.85

  // // radial blur distance when idle
  // public static readonly RADIAL_BLUR_DIST_BASE: number = 0.5 // 0.5
  // // radial blur distance when zooming through
  // public static readonly RADIAL_BLUR_DIST: number = 0.7

  // // radial blur intensity when idle
  // public static readonly RADIAL_BLUR_INTENSITY_BASE: number = 1.4 // 1.8
  // // radial blur intensity when zooming through
  // public static readonly RADIAL_BLUR_INTENSITY: number = 2.5

  // // original image intensity behind radial blur when idle
  // public static readonly RADIAL_BLUR_ORIG_INTENSITY: number = 1.1 // 1.0
  // // original image intensity behind radial blur when zooming through
  // public static readonly RADIAL_BLUR_ORIG_INTENSITY_REDUCED: number = 0.7

  // // hardness of radial blur ends when idle (0 = harder edge blur, 1 = softer edge blur)
  // public static readonly RADIAL_BLUR_EDGE_BRIGHTNESS: number = 0.8 // 0.9
  // // hardness of radial blur ends when zooming through (0 = harder edge blur, 1 = softer edge blur)
  // public static readonly RADIAL_BLUR_EDGE_BRIGHTNESS_REDUCED: number = 1

  // amount of extra glow brightness to add within the lines
  public static readonly GLOW_INTENSITY: number = 2.0
  // how tight inside the lines the glow is applied
  public static readonly GLOW_SHARPNESS: number = 3.0

  // chladni pattern codes
  // every second pattern is one of the simpler transitionary patterns (the transition stops on every second pattern)
  // a transition pattern must be at the end to enable looping back around to the first pattern
  // a wide range of pattern codes from ALL_CHLADNI_FORMS array below
  // these can be browsed by adding ?all to the browser query string at end of url
  // browse by scrolling through patterns and copying specific pattern codes from the console

  public static readonly CHLADNI_FORMS: any = [
    [11, 12, 1, 1], // pattern 1
    [10, 1, 1, 1], // - transition pattern
    [13, 5, 0, 0], // pattern 2
    [6, 5, 0, 1], // - transition pattern
    [5, 12, 1, 1], // pattern 3
    [3, 5, 1, 1], // - transition pattern
    [13, 9, 0, 0], // pattern 4
    [5, 2, 0, 1], // - transition pattern
    [11, 6, 0, 0], // pattern 5
    [5, 7, 0, 0], // - transition pattern

    // [13, 12, 1, 1],
    // [7, 3, 0.5, 0.5],
    // [6, 13, 1, 0],
    // [4, 1, 1, 0],
  ]

  // colors for the two cladni color points when cycling through patterns
  // number of colors should be double the number of CHLADNI_FORMS
  // color pairs are in the format: [red, green blue] with values 0 to 1
  //public static readonly SECTION_COLORS: any = [
  public static SECTION_COLORS: any = [
    [
      // pattern 1
      [235 / 255, 194 / 255, 82 / 255],
      [209 / 255, 120 / 255, 25 / 255], // [209 / 255, 102 / 255, 25 / 255], // [209 / 255, 55 / 255, 25 / 255],
    ],
    // [
    //   // pattern 2
    //   [0, 0.9, 0.7], // green
    //   [0.1, 0.5, 1], // blue
    // ],
    // [
    //   // pattern 3
    //   [1, 0.7, 0], // yellow
    //   [0.9, 0.5, 0], // orange
    // ],
    // [
    //   // pattern 4
    //   [1, 0, 1], // purple
    //   [0.33, 0, 1], // blue
    // ],
    // [
    //   // pattern 5
    //   [0, 0.3, 0.8], // blue
    //   [0.1, 1, 1], // aqua
    // ],
  ]

  // additional selected chladni forms
  //     // complex
  //     [13, 5, 0, 0],
  //     [14, 3, 1, 1],
  //     [13, 12, 1, 1],
  //     [10, 7, 0, 0],
  //     [5, 12, 1, 1],
  //     [9, 5, 0, 0],
  //     [9, 12, 0, 0],
  //     [13, 9, 0, 0],
  //     [5, 14, 0, 0],
  //     [11, 6, 0, 0],
  //     [13, 8, 1, 1],
  //     [14, 10, 0, 0],
  //     [13, 12, 1, 1],
  //     [6, 13, 1, 0],
  //     [6, 11, 1, 0],

  //     // simple
  //     [5, 2, 1, 1],
  //     [4, 1, 1, 0],
  //     [3, 4, 1, 0],
  //     [3, 5, 1, 1],
  //     [7, 3, 0.5, 0.5],
  //     [1, 6, 0, 1],
  //     [1, 7, 0, 0],
  //     [2, 6, 1, 1], // x|x
  //     [3, 7, 0, 0],
  //     [3, 5, 0.5, 0.5],
  //     [5, 2, 0, 1],

  //     [5, 8, 1, 0],

  //     // [1, 4, 0.5, 0.5],
  //     [6, 9, 0, 0],

  //     [2, 10, 0, 0.5],
  //     [7, 4, 0, 0],
  //     [5, 7, 0, 0],
  //     [10, 1, 1, 1],
  //     [8, 9, 0, 0],
  //     [7, 6, 1, 1],
  //     [7, 1, 1, 0],
  //     [6, 5, 0, 1],
  //     [9, 4, 1, 0],
  //     // [3, 8, 0.5, 0.5],
  //     [8, 3, 1, 1],
  //     [11, 2, 0, 0],
  //     [10, 2, 0, 0],
  //     [3, 7, 0, 1],
  //     // [-3, -7, 0, -1],
  //     [8, 3, 0, 1],
  //     [4, 10, 0, 0],
  //     [3, 12, 0, 0],
  //     [2, 15, 1, 0],
  //     [6, 12, 0, 1],
  //     [3, 13, 1, 0],
  //     [2, 12, 1, 1],
  //     [2, 9, 1, 0],
  //     [2, 7, 1, 1],
  //     [6, 14, 1, 0],
  //     [3, 11, 1, 0],
  //     [4, 5, 0, 0],
  //     [10, 13, 1, 0],
  //     // [-2, -13, 0, -1],
  //     [2, 13, 0, 1],

  //     // [3, 2, 1, 1],
  //     [2, 3, 0, 0],
  //     [4, 1, 0, 1],
  //     // [1, 4, 0, 0],
  //     [2, 5, 1, 1],
  //     // [1, 6, 0, 1.5],
  //     [3, 8, 0, 0],
  //     [5, 8, 0, 0],
  //     [5, 8, 0, 0],

  // a wide range of chladni pattern codes
  // can be browsed by adding ?all to the browser query string at end of url
  // browse by scrolling through patterns and copying specific pattern codes from the console
  public static readonly ALL_CHLADNI_FORMS: any = [
    [13, 5, 0, 0],
    // [14, 3, 1, 1],
    // [13, 12, 1, 1],
    [6, 9, 0, 0],

    [2, 10, 0, 0.5],
    [7, 4, 0, 0],
    [5, 7, 0, 0],
    [10, 1, 1, 1],
    [11, 12, 1, 1],
    [8, 9, 0, 0],
    [7, 6, 1, 1],
    [7, 1, 1, 0],
    [6, 5, 0, 1],
    [9, 4, 1, 0],
    [10, 7, 0, 0],
    [5, 12, 1, 1],
    [8, 3, 1, 1],
    [11, 2, 0, 0],
    [9, 5, 0, 0],
    [9, 12, 0, 0],
    [13, 9, 0, 0],
    [10, 2, 0, 0],
    [3, 7, 0, 1],
    [8, 3, 0, 1],
    [4, 10, 0, 0],
    [3, 12, 0, 0],
    [2, 15, 1, 0],
    [6, 12, 0, 1],
    [5, 14, 0, 0],
    [3, 13, 1, 0],
    [2, 12, 1, 1],
    [2, 9, 1, 0],
    [2, 7, 1, 1],
    [3, 5, 1, 1],
    [3, 4, 1, 0],
    [5, 2, 1, 1],
    [4, 1, 1, 0],
    [11, 6, 0, 0],
    [13, 8, 1, 1],
    [14, 10, 0, 0],
    [13, 12, 1, 1],
    [6, 14, 1, 0],
    [6, 13, 1, 0],
    [3, 11, 1, 0],
    [4, 5, 0, 0],
    [10, 13, 1, 0],
    [2, 13, 0, 1],
    [6, 11, 1, 0],
    [2, 3, 0, 0],
    [4, 1, 0, 1],
    [2, 5, 1, 1],
    [7, 3, 0.5, 0.5],
    [1, 6, 0, 1],
    [1, 7, 0, 0],
    [2, 6, 1, 1],
    [3, 7, 0, 0],
    [5, 8, 1, 0],
    [3, 8, 0, 0],
    [3, 5, 0.5, 0.5],
    [5, 8, 0, 0],
    [5, 8, 0, 0],
    [5, 2, 0, 1],
    [1, 4, 0.5, 0.5],
    [3, 8, 0.5, 0.5],
    [-3, -7, 0, -1],
    [-2, -13, 0, -1],
    [3, 2, 1, 1],
    [1, 4, 0, 0],
    [1, 6, 0, 1.5],
  ]
}
