import { atom } from 'recoil'

/**
 * Store menu state here
 *
 * @usage
 * const [isBgDarkened, setIsBgDarkened] = useRecoilValue(bgDarkenedState)
 */

export const bgDarkenedState = atom({
  key: 'bgDarkenedState', // unique ID (with respect to other atoms/selectors)
  default: false,
})
