// pulled from stage.html
// example: https://github.com/react-spring/react-three-fiber/blob/master/examples/src/demos/dev/ShaderMaterial.js

const vertexShader = `
  varying vec2 vUv;
  void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`

const fragmentShader = `
  precision highp float;

  varying vec2 vUv;

  uniform float viewWidthRatio;

  uniform float noiseIntensity;

  uniform float time;
  uniform float zoom;
  uniform float patternZoom;

  uniform vec2 touchPos;
  uniform float touchSpeed;

  uniform vec2 colorPos1;
  uniform vec2 colorPos2;

  uniform vec3 color1;
  uniform vec3 color2;

  uniform float colorSize1;
  uniform float colorSize2;
  uniform float colorIntensity1;
  uniform float colorIntensity2;

  uniform float glowSharpness;
  uniform float glowIntensity;

  uniform vec2 viewOffset;

  uniform vec2 offsetShift;
  uniform vec2 centerShift;

  uniform vec2 shiftA;
  uniform vec2 offsetA;
  uniform float zoomA;

  uniform vec2 shiftB;
  uniform vec2 offsetB;
  uniform float zoomB;

  uniform float curPosition;

  uniform float waveBlendAmount;


  float saturateValue(float value) {
    return clamp(value, 0.0, 1.0);
  }

  float sinBlend(float amount) {
      return 0.5 - 0.5 * cos(amount * 3.14159265);
  }

  float rand(vec2 co){
      return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
  }

  float edgePow(float val, float amount){
      return pow(abs(val), amount) * (1.0 - 2.0 * step(val, 0.0));
  }

  void main() {
      float PI = 3.14159265359;

      float animationTime = time * 0.4;

      vec4 outputColor = vec4(1.0, 1.0, 1.0, 1.0);

      vec2 aspectRatio = mix(vec2(viewWidthRatio, 1.0), vec2(1.0, 1.0 / viewWidthRatio), 0.5);


      float offsetShiftAmount = (offsetShift.x * offsetShift.y);

      vec2 touchDelta = (touchPos - vUv) * aspectRatio;
      float touchDist = sqrt(touchDelta.x * touchDelta.x + touchDelta.y * touchDelta.y);
      touchDist = (pow(1.0 - saturateValue(touchDist / 0.9), 5.0) * saturateValue(touchSpeed * 1.5) * 100.0) * 0.7;


      // vec2 coord = (vUv - 0.5) * aspectRatio * zoom;
      vec2 coordOrigin = (vUv - 0.5) * aspectRatio;


      float dist = sqrt(coordOrigin.x * coordOrigin.x + coordOrigin.y * coordOrigin.y);
      float offsetDist = pow(dist, 0.5);

      float radialPosition = atan(coordOrigin.x, coordOrigin.y) / 3.14159265359;
      float radialPositionBase = radialPosition;
      // radialPosition -= animationTime * 0.01;

      radialPosition = 0.5 + 0.5 * edgePow(cos(radialPosition * 6.28318 * 3.0), 2.0);

      // radialPosition *= (1.0 - 2.0 * step(coordOrigin.x, 0.0));

      vec2 coord = vec2(0.0);
      coord.x = radialPosition * 0.2;
      coord.y = offsetDist;


      coord *= zoom;
      coord *= 0.25;
      

      vec2 baseCoord = coord;

      // float dist = sqrt(coord.x * coord.x + coord.y * coord.y);

      coord += viewOffset;

      // bulge
      // coord /= 1.0 + pow(dist, 1.5) * 0.5;

      coord *= patternZoom;


      vec2 warpOffset = centerShift.yx * 10.0;

      vec2 warpOffsetA = vec2(0.0);
      warpOffsetA.x += sin(coord.x * 9.354 + 0.356 + warpOffset.x * 3.3 + animationTime * 1.1) * sin(coord.y * 7.435 + 0.854 + warpOffset.x * 3.6 + animationTime * 0.7) * 0.01;
      warpOffsetA.y += sin(coord.x * 8.564 + 1.3423 + warpOffset.y * 3.2 + animationTime * 0.7) * sin(coord.y * 8.234 + 1.74 + warpOffset.x * 3.1 + animationTime * 1.3) * 0.01;
      warpOffsetA.x += sin(coord.x * 12.34 + 2.43 + warpOffset.x * 5.645 - animationTime * 0.834) * sin(coord.y * 13.43 + 1.23 + warpOffset.x * 4.234 - animationTime * 1.23) * 0.01;
      warpOffsetA.y += sin(coord.x * 11.34 + 0.43 + warpOffset.y * 4.564 - animationTime * 1.234) * sin(coord.y * 16.54 + 2.643 + warpOffset.x * 5.54 - animationTime * 0.74) * 0.01;
      
      // increase offset further from center
      // warpOffsetA *= pow(dist, 2.2) * 1.2;


      warpOffsetA += touchDelta * touchDist * 0.2;

      offsetShiftAmount = mix(offsetShiftAmount, 1.0, sinBlend(saturateValue(dist * 0.5)));


      vec2 offsetCoordA = (coord * zoomA + warpOffsetA - offsetA) + centerShift.yx * zoom;
      vec2 offsetCoordAScaled = (coord * zoomA * offsetShiftAmount - warpOffsetA.xy * 2.0 - offsetA) + centerShift.yx * zoom;

      vec2 offsetCoordB = (coord * zoomB + warpOffsetA - offsetB) + centerShift.yx * zoom;
      vec2 offsetCoordBScaled = (coord * zoomB * offsetShiftAmount - warpOffsetA.xy * 2.0 - offsetB) + centerShift.yx * zoom;


      float waveAValueX1 = cos(offsetCoordA.x * shiftA.x);
      float waveAValueX2 = cos(offsetCoordAScaled.y * shiftA.y);
      float waveAValueY1 = cos(offsetCoordAScaled.x * shiftA.y);
      float waveAValueY2 = cos(offsetCoordA.y * shiftA.x);

      float waveBValueX1 = cos(offsetCoordB.x * shiftB.x);
      float waveBValueX2 = cos(offsetCoordBScaled.y * shiftB.y);
      float waveBValueY1 = cos(offsetCoordBScaled.x * shiftB.y);
      float waveBValueY2 = cos(offsetCoordB.y * shiftB.x);

      float waveAValueX = waveAValueX1 * waveAValueX2;
      float waveAValueY = waveAValueY1 * waveAValueY2;

      float waveBValueX = waveBValueX1 * waveBValueX2;
      float waveBValueY = waveBValueY1 * waveBValueY2;

      float waveValueA = waveAValueX - waveAValueY;
      float waveValueB = waveBValueX - waveBValueY;

      float waveValue = mix(waveValueA, waveValueB, waveBlendAmount);

      waveValue += warpOffsetA.x * 10.0  + warpOffsetA.x * 10.0;

      float intensityLine = (1.0 / (0.0 + waveValue * waveValue * (150.0 * (0.6 + warpOffsetA.x * 0.5  + warpOffsetA.x * 0.5))) - 0.4);
      // float intensityLine = (1.0 / (0.0 + waveValue * waveValue * (300.0 * (0.6 + warpOffsetA.x * 0.5  + warpOffsetA.x * 0.5))) - 0.3);
      intensityLine = saturate(intensityLine);

      //intensityLine = 0.1 + 0.9 * intensityLine;

      //intensityLine += warpOffsetA.x * 2.0  + warpOffsetA.x * 2.0;
      

      // darkening
      intensityLine *= pow(min(dist * 4.0, 1.0), 2.0);
      intensityLine *= pow(min(dist * 1.5, 1.0), 0.6);


      outputColor.rgb = vec3(intensityLine);

      vec2 centerCoord = vUv - 0.5;

      vec2 colorPoint1Delta = (colorPos1 - centerCoord) * aspectRatio;
      float colorPoint1Dist = saturateValue((1.0 - colorSize1 * sqrt(colorPoint1Delta.x * colorPoint1Delta.x + colorPoint1Delta.y * colorPoint1Delta.y)) * colorIntensity1);
      float color1Amount = colorPoint1Dist;
      // outputColor.rgb *= mix(vec3(1.0), color1, color1Amount);


      vec2 colorPoint2Delta = (colorPos2 - centerCoord) * aspectRatio;
      float colorPoint2Dist = saturateValue((1.0 - colorSize2 * sqrt(colorPoint2Delta.x * colorPoint2Delta.x + colorPoint2Delta.y * colorPoint2Delta.y)) * colorIntensity2);
      float color2Amount = colorPoint2Dist;
      // outputColor.rgb *= mix(vec3(1.0), color2, color2Amount);


      float totalColorAmount = max(0.001, saturateValue(color1Amount) + saturateValue(color2Amount));
      float blendedColorAmount = saturateValue(color2Amount) / totalColorAmount;

      vec3 blendedColor = mix(color1, color2, blendedColorAmount);

      float blendedOffAmount = min(1.0, totalColorAmount);
      blendedColor = mix(vec3(1.0), blendedColor, blendedOffAmount);

      // blend color
      outputColor.rgb *= blendedColor;


      float noiseValue = rand(coordOrigin * 13.7 + time);
      noiseValue *= noiseValue;
      noiseValue = 1.0 - noiseValue * noiseIntensity; // 0.18
      outputColor.rgb *= noiseValue;

      // debug
      // outputColor.rgb = vec3(color1Amount);
      // outputColor.rgb = mix(vec3(1.0), color1, color1Amount);
      // outputColor.rgb = mix(vec3(0.0), vec3(1.0), saturateValue(color1Amount));

      // outputColor.r += spiralOffset;

      // outputColor.r = radialPosition;

      outputColor.rgb += pow(outputColor.rgb, vec3(glowSharpness)) * glowIntensity;

      gl_FragColor = outputColor;
      
      // gl_FragColor = vec4(coord.x, coord.y, 0.0, 1.0);
  }
`

export { vertexShader, fragmentShader }
export default {}; // export default so gatsby doesn't complain for react components on npm: build