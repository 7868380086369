import Config from './Config'
import SceneModel from './SceneModel'

class MouseMoveController {
  public readonly touchDelta: { x: number; y: number } = { x: 0, y: 0 }
  public readonly lastTouchPos: { x: number; y: number } = { x: 0, y: 0 }
  public totalTouchDist: number = 0

  public start(): void {
    this.addEvents()
  }

  private addEvents = (): void => {
    if (typeof window !== 'undefined') {
      if (!Config.MOBILE) {
        window.addEventListener('mousemove', this.onMouseMove)
      } else {
        window.addEventListener('touchmove', this.onMouseMove)
      }
    }
  }

  private onMouseMove = (event: any): void => {
    const touch: Touch = this.getTouch(event)

    const curAbsTouchPos: { x: number; y: number } = this.getTouchPoint(touch)

    SceneModel.touchPos.x = curAbsTouchPos.x / window.innerWidth
    SceneModel.touchPos.y = curAbsTouchPos.y / window.innerHeight

    this.touchDelta.x = SceneModel.touchPos.x - this.lastTouchPos.x
    this.touchDelta.y = SceneModel.touchPos.y - this.lastTouchPos.y

    this.lastTouchPos.x = SceneModel.touchPos.x
    this.lastTouchPos.y = SceneModel.touchPos.y

    this.totalTouchDist += Math.sqrt(
      this.touchDelta.x * this.touchDelta.x +
        this.touchDelta.y * this.touchDelta.y
    )
  }

  private getTouchPoint(touch: any): { x: number; y: number } {
    return { x: touch.pageX, y: touch.pageY }
  }

  private getTouch(event: any, includeChangedTouches: boolean = false): Touch {
    if (event.touches && event.touches.length) {
      event = event.touches[0]
    } else if (event.changedTouches && event.changedTouches.length) {
      event = event.changedTouches[0]
    }
    return event
  }
}

export default new MouseMoveController()
