import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import * as styles from './AudioIcon.styles.scss'

const AudioIcon = ({ isMuted, isActive, isAnimated, size }) => {
  const numLines = size === 'small' ? 10 : 21

  return (
    <div
      className={classNames(styles.AudioIcon, {
        [styles.isMuted]: !!isMuted,
        [styles.isActive]: !!isActive,
        [styles.isAnimated]: !!isAnimated,
        [styles[size]]: !!size,
      })}
    >
      {[...Array(numLines)].map((_, index) => {
        return (
          <span className={styles.line} key={`line-${index}`}>
            <span className={styles.line__inner} />
          </span>
        )
      })}
    </div>
  )
}

AudioIcon.defaultProps = {
  size: 'large',
}

AudioIcon.propTypes = {
  isMuted: PropTypes.bool,
  isActive: PropTypes.bool,
  isAnimated: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large']),
}

export { AudioIcon }
