import React from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { mutedState } from '@/store'
import ReactPlayer from 'react-player'

const SoundEffect = React.forwardRef(({ url, isPlaying, volume }, ref) => {
  const isMuted = useRecoilValue(mutedState)

  return (
    <ReactPlayer
      url={url}
      playing={isPlaying}
      loop={false}
      volume={volume}
      muted={isMuted}
      controls={false}
      style={{ opacity: 0 }}
      width={0}
      height={0}
      ref={ref}
    />
  )
})

SoundEffect.defaultProps = {
  volume: 1,
  isPlaying: false,
}

SoundEffect.propTypes = {
  url: PropTypes.string,
  volume: PropTypes.number,
  isPlaying: PropTypes.bool,
}

export { SoundEffect }
