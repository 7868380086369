import { atom } from 'recoil'

/**
 * Store loaded state
 *
 * @usage
 * const isReady = useRecoilValue(readyState)
 */

export const readyState = atom({
  key: 'readyState', // unique ID (with respect to other atoms/selectors)
  default: false,
})
