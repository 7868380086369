import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { readyState, defaultMusicState } from '@/store'
import { AudioIcon } from '@/components/AudioIcon'
import { SoundEffect } from '@/components/SoundEffect'
import { preloadAudio } from '@/utils/preloadAudio'
import * as styles from './Intro.styles.scss'
import logoWebp from '@/assets/opus-logo-full.webp'
import logoPng from '@/assets/opus-logo-full.png'
import { useTransition } from './Intro.gsap'

const LOAD_TIMEOUT = 8000

const Intro = ({ enterSound, loadingText, preloadAudioFiles, readyText }) => {
  const timeOutRef = useRef()
  const [isLoading, setIsLoading] = useState(true)

  const soundPlayer = useRef({})
  const [hasClicked, setHasClicked] = useState(false)
  const [isReady, setIsReady] = useRecoilState(readyState)
  const isVisible = !isReady
  const setIsDefaultMusicPlaying = useSetRecoilState(defaultMusicState)

  const ref = useRef()

  useTransition(ref, {
    isReady,
    isLoading,
    selectors: {
      bg: `.${styles.bg}`,
      logo: `.${styles.logo}`,
      labelLoading: `.${styles.status__loading}`,
      labelReady: `.${styles.status__ready}`,
      audioIcon: `.${styles.audioIcon}`,
    },
  })

  const handleOnClick = () => {
    setIsReady(true)
    setIsDefaultMusicPlaying(true)

    soundPlayer.current.player?.seekTo(0)
    setHasClicked(true)
  }

  const handleAllAudioLoaded = () => {
    setIsLoading(false)
    clearTimeout(timeOutRef.current)
  }

  useEffect(() => {
    if (preloadAudioFiles.length) {
      preloadAudio(preloadAudioFiles, handleAllAudioLoaded)
      timeOutRef.current = setTimeout(() => {
        console.warn(`intro: error loading some audio within ${LOAD_TIMEOUT}ms`) // eslint-disable-line no-console
        setIsLoading(false)
      }, LOAD_TIMEOUT)
    } else {
      setIsLoading(false)
    }
  }, [JSON.stringify(preloadAudioFiles)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={ref}
      className={styles.Intro}
      style={{ pointerEvents: isVisible ? 'auto' : 'none' }}
    >
      {enterSound && (
        <SoundEffect
          ref={soundPlayer}
          url={enterSound}
          isPlaying={hasClicked}
        />
      )}
      <div className={styles.bg} />
      <button
        type="button"
        onClick={handleOnClick}
        className={styles.button}
        style={{ pointerEvents: (isLoading || !isVisible) && 'none' }}
      >
        <div className={styles.logo}>
          <div className={styles.logo__inner}>
            <picture>
              <source srcSet={logoWebp} type="image/webp" />
              <source srcSet={logoPng} type="image/png" />
              <img
                src={logoPng}
                alt="Opus Music Group"
                className={styles.logo__inner}
              />
            </picture>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.audioIcon}>
            <AudioIcon size="large" isAnimated />
          </div>
          <div className={styles.status}>
            <div className={styles.status__loading}>{loadingText}</div>
            <div className={styles.status__ready}>{readyText}</div>
          </div>
        </div>
      </button>
    </div>
  )
}

Intro.defaultProps = {
  loadingText: `Loading`,
  preloadAudioFiles: [],
  readyText: `Click anywhere to begin`,
}

Intro.propTypes = {
  enterSound: PropTypes.string,
  loadingText: PropTypes.node,
  preloadAudioFiles: PropTypes.array,
  readyText: PropTypes.node,
}

export { Intro }
