import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { SliderButton } from '@typeform/embed-react'

import { globalHistory } from '@reach/router'
import { Link } from '@/components/Link'
import { SoundHover } from '@/components/SoundHover'
import * as styles from './Header.styles.scss'
import LogoSVG from '@/assets/logo.inline.svg'

const Header = ({
  hoverSound,
  title,
  navPrimary,
  // navSecondary,
  isFixed,
  socialLabel,
  socialLinks,
  typeformId,
}) => {
  const [socialVisible, setSocialVisible] = useState(false)
  const currentPath = globalHistory.location.pathname

  return (
    <div className={styles.Header} data-fixed-position={isFixed}>
      <div>
        <h1 className={styles.logo} aria-label={title}>
          <SoundHover url={hoverSound}>
            <Link to="/" className={styles.logo__link}>
              <LogoSVG />
            </Link>
          </SoundHover>
        </h1>
        <div className={styles.nav}>
          <div className={styles.nav__primary}>
            {!!navPrimary.length && (
              <ul className={styles.nav__list}>
                {navPrimary.map((item) => (
                  <li key={item.uid} className={styles.nav__item}>
                    <SoundHover url={hoverSound}>
                      <span
                        className={styles.nav__underline}
                        style={
                          item.url === currentPath
                            ? { opacity: 1, transform: 'scaleX(1)' }
                            : { opacity: 0, transform: 'scaleX(0)' }
                        }
                      />
                      <Link to={item.url}>{item.title}</Link>
                    </SoundHover>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className={styles.nav__secondary}>
            {/* {!!navSecondary.length && renderNavList(navSecondary)} */}
            {typeformId && (
              <div className={styles.contact}>
                <SliderButton id={typeformId}>
                  <SoundHover url={hoverSound}>Contact</SoundHover>
                </SliderButton>
              </div>
            )}
            {!!socialLinks.length && socialLabel && (
              <SoundHover url={hoverSound}>
                <button
                  type="button"
                  className={styles.social}
                  onMouseEnter={() => setSocialVisible(true)}
                  onMouseLeave={() => setSocialVisible(false)}
                >
                  <div className={styles.social__label}>{socialLabel}</div>
                  <div
                    className={styles.social__links}
                    style={
                      socialVisible
                        ? { pointerEvents: 'auto', opacity: 1 }
                        : { pointerEvents: 'none', opacity: 0 }
                    }
                  >
                    {socialLinks.map((item) => (
                      <div key={item.uid} className={styles.social__item}>
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noreferrer"
                          className={styles.social__link}
                        >
                          {item.title}
                        </a>
                      </div>
                    ))}
                  </div>
                </button>
              </SoundHover>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

Header.defaultProps = {
  isFixed: false,
  navPrimary: [],
  // navSecondary: [],
  socialLabel: 'Social',
  socialLinks: [],
}

Header.propTypes = {
  hoverSound: PropTypes.string,
  isFixed: PropTypes.bool,
  navPrimary: PropTypes.arrayOf(PropTypes.object),
  // navSecondary: PropTypes.arrayOf(PropTypes.object),
  socialLabel: PropTypes.string,
  socialLinks: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  typeformId: PropTypes.string,
}

export { Header }
