import Config from './Config';
import SceneConfig from './SceneConfig';

import { lerp, sinBlend } from '../resources/MathUtil'

class SceneModel {

    public time: number = 0;
    public lastTime: number = 0;

    public scrollIndex: number = 0;
    public scrollDirection: number = 1;

    public targetPatternPosition: number = 0;
    public curPatternPosition: number = 0;
    public patternPositionVel: number = 0;

    public zoomSpeed: number = 0;
    public zoomSpeedVel: number = 0;

    public curTouchSpeed: number = 0;
    public touchSpeedVel: number = 0;

    public targetDistortionAmount: number = 0;
    public curDisortionAmount: number = 0;
    public distortionAmountVel: number = 0;


    public pressed: boolean = false;

    public readonly touchPos: { x: number, y: number } = { x: 0.5, y: 0.5 };

    public readonly curTouchPos: { x: number, y: number } = { x: 0.5, y: 0.5 };
    public readonly curTouchPosVel: { x: number, y: number } = { x: 0, y: 0 };

    public readonly lastTouchPos: { x: number, y: number } = { x: 0.5, y: 0.5 };

    public readonly targetShift: { x: number, y: number } = { x: 0, y: 0 };
    public readonly targetShiftVel: { x: number, y: number } = { x: 0, y: 0 };

    public readonly viewOffsetTarget: { x: number, y: number } = { x: 0, y: 0 };
    public readonly viewOffset: { x: number, y: number } = { x: 0, y: 0 };
    public readonly viewOffsetVel: { x: number, y: number } = { x: 0, y: 0 };

    public readonly targetShiftOffset: { x: number, y: number } = { x: 0, y: 0 };
    public readonly targetShiftOffsetVel: { x: number, y: number } = { x: 0, y: 0 };

    public readonly curTouchOffset: { x: number, y: number } = { x: 0.5, y: 0.5 };
    public readonly touchOffset: { x: number, y: number } = { x: 0.5, y: 0.5 };

    public readonly targetOffsetShift: { x: number, y: number } = { x: 1, y: 1 };
    public readonly offsetShift: { x: number, y: number } = { x: 1, y: 1 };
    public readonly offsetShiftVel: { x: number, y: number } = { x: 0, y: 0 };

    public readonly targetCenterShift: { x: number, y: number } = { x: 0, y: 0 };
    public readonly centerShift: { x: number, y: number } = { x: 0, y: 0 };
    public readonly centerShiftVel: { x: number, y: number } = { x: 0, y: 0 };

    private targetBlendAmount: number = 0;
    private waveBlendAmount: number = 0;
    private colorBlendAmount: number = 0;

    private distortionAmount: number = 0;

    private zoomAAmount: number = 1;
    private targetZoomAAmount: number = 1;
    private zoomBAmount: number = 1;
    private targetZoomBAmount: number = 1;

    private touchSpeed: number = 0;
    private absTouchSpeed: number = 0;

    private curShiftX: number = 0;
    private curShiftY: number = 0;
    private curOffsetX: number = 0;
    private curOffsetY: number = 0;

    private readonly PI: number = 3.14159265359;

    public renderShader: any = null;

    private updatePattern(): void {
        const formSet: any = SceneConfig.ALL_FORMS ? SceneConfig.ALL_CHLADNI_FORMS : SceneConfig.CHLADNI_FORMS;

        let curPatternProgress: number = this.curPatternPosition * 2;
        const curColorProgress: number = this.curPatternPosition;

        if (SceneConfig.ALL_FORMS) {
            curPatternProgress = this.curPatternPosition;
        }

        const formAIndex: number = Math.floor((curPatternProgress + formSet.length * 999) % formSet.length);
        const formBIndex: number = Math.floor((curPatternProgress + formSet.length * 999 + 1) % formSet.length);
        const cladniFormA: any = formSet[formAIndex];
        const cladniFormB: any = formSet[formBIndex];

        const formAColorIndex: number = Math.floor((curColorProgress + SceneConfig.SECTION_COLORS.length * 999) % SceneConfig.SECTION_COLORS.length);
        const formBColorIndex: number = Math.floor((curColorProgress + SceneConfig.SECTION_COLORS.length * 999 + 1) % SceneConfig.SECTION_COLORS.length);
        const formAColors: any = SceneConfig.SECTION_COLORS[formAColorIndex]; //[[1, 0, 0], [0, 1, 0]]; //
        const formBColors: any = SceneConfig.SECTION_COLORS[formBColorIndex];

        if (cladniFormA && cladniFormB) {
            this.targetBlendAmount = curPatternProgress - Math.floor(curPatternProgress);
            this.waveBlendAmount = this.targetBlendAmount;

            this.colorBlendAmount = curColorProgress - Math.floor(curColorProgress);

            const waveBlendAmount: number = lerp(sinBlend(this.waveBlendAmount), this.waveBlendAmount, 0.5);
            this.renderShader.uniforms.waveBlendAmount.value = waveBlendAmount;

            this.targetShift.x = cladniFormA[0];
            this.targetShift.y = cladniFormA[1];
            this.targetShiftOffset.x = cladniFormA[2];
            this.targetShiftOffset.y = cladniFormA[3];

            this.updatePatternAttributes(0);

            this.targetShift.x = cladniFormB[0];
            this.targetShift.y = cladniFormB[1];
            this.targetShiftOffset.x = cladniFormB[2];
            this.targetShiftOffset.y = cladniFormB[3];

            this.updatePatternAttributes(1);

            if (this.targetBlendAmount < 0.5) {
                this.targetShift.x = cladniFormA[0];
                this.targetShift.y = cladniFormA[1];
                this.targetShiftOffset.x = cladniFormA[2];
                this.targetShiftOffset.y = cladniFormA[3];
            }

            if ((this.curShiftX !== this.targetShift.x ||
                this.curShiftY !== this.targetShift.y ||
                this.curOffsetX !== this.targetShiftOffset.x ||
                this.curOffsetY !== this.targetShiftOffset.y)
            ) {
                this.curShiftX = this.targetShift.x;
                this.curShiftY = this.targetShift.y;
                this.curOffsetX = this.targetShiftOffset.x;
                this.curOffsetY = this.targetShiftOffset.y;

                if (SceneConfig.ALL_FORMS || Config.DEV) {
                    console.log('chladni shape: [' + this.curShiftX + ', ' + this.curShiftY + ', ' + this.curOffsetX + ', ' + this.curOffsetY + ']');
                }
            }

            this.zoomAAmount = 1;
            this.targetZoomAAmount = 1 / SceneConfig.ZOOM_AMOUNT;
            this.zoomBAmount = SceneConfig.ZOOM_AMOUNT;
            this.targetZoomBAmount = 1;
            this.renderShader.uniforms.zoomA.value = lerp(this.zoomAAmount, this.targetZoomAAmount, this.waveBlendAmount);
            this.renderShader.uniforms.zoomB.value = lerp(this.zoomBAmount, this.targetZoomBAmount, this.waveBlendAmount);

            this.renderShader.uniforms.curPosition.value = curPatternProgress;

            const formAColor1: any = formAColors[0];
            const formAColor2: any = formAColors[1];

            const formBColor1: any = formBColors[0];
            const formBColor2: any = formBColors[1];

            this.renderShader.uniforms.color1.value.x = lerp(formAColor1[0], formBColor1[0], this.colorBlendAmount);
            this.renderShader.uniforms.color1.value.y = lerp(formAColor1[1], formBColor1[1], this.colorBlendAmount);
            this.renderShader.uniforms.color1.value.z = lerp(formAColor1[2], formBColor1[2], this.colorBlendAmount);

            this.renderShader.uniforms.color2.value.x = lerp(formAColor2[0], formBColor2[0], this.colorBlendAmount);
            this.renderShader.uniforms.color2.value.y = lerp(formAColor2[1], formBColor2[1], this.colorBlendAmount);
            this.renderShader.uniforms.color2.value.z = lerp(formAColor2[2], formBColor2[2], this.colorBlendAmount);
        }
    }

    private updatePatternAttributes(curPatternIndex: number): void {
        const curTargetShiftUniform: any = (curPatternIndex === 0) ? this.renderShader.uniforms.shiftA : this.renderShader.uniforms.shiftB;
        const curTargetOffsetUniform: any = (curPatternIndex === 0) ? this.renderShader.uniforms.offsetA : this.renderShader.uniforms.offsetB;

        curTargetShiftUniform.value.x = this.targetShift.x * this.PI;
        curTargetShiftUniform.value.y = this.targetShift.y * this.PI;

        curTargetOffsetUniform.value.x = Math.abs(this.targetShiftOffset.x);
        curTargetOffsetUniform.value.y = Math.abs(this.targetShiftOffset.y);
    }

    public update = (time: number): void => {
        this.time = time;

        const timeDelta: number = time - this.lastTime;

        if (timeDelta < 1 / SceneConfig.MAX_FPS) { return; }
        this.lastTime = this.time;

        if (this.renderShader) {
            this.renderShader.uniforms.time.value = SceneConfig.ANIM_SPEED * this.time;

            const absTouchDistX: number = this.touchPos.x - this.lastTouchPos.x;
            const absTouchDistY: number = this.touchPos.y - this.lastTouchPos.y;
            this.absTouchSpeed = Math.sqrt(absTouchDistX * absTouchDistX + absTouchDistY * absTouchDistY);

            this.lastTouchPos.x = this.touchPos.x;
            this.lastTouchPos.y = this.touchPos.y;

            const touchDistX: number = this.touchPos.x - this.curTouchPos.x;
            const touchDistY: number = this.touchPos.y - this.curTouchPos.y;
            const touchDist: number = Math.sqrt(touchDistX * touchDistX + touchDistY * touchDistY);

            if (touchDist > 0.2 && this.renderShader.uniforms.touchSpeed.value < SceneConfig.TOUCH_MIN_SPEED) {
                this.curTouchPos.x = this.touchPos.x;
                this.curTouchPos.y = this.touchPos.y;

                this.curTouchPosVel.x = 0;
                this.curTouchPosVel.y = 0;
            } else {
                this.curTouchPosVel.x += (this.touchPos.x - this.curTouchPos.x) * SceneConfig.TOUCH_MOVE_ACCEL;
                this.curTouchPosVel.y += (this.touchPos.y - this.curTouchPos.y) * SceneConfig.TOUCH_MOVE_ACCEL;
                this.curTouchPosVel.x *= SceneConfig.TOUCH_MOVE_DECEL;
                this.curTouchPosVel.y *= SceneConfig.TOUCH_MOVE_DECEL;

                this.curTouchPos.x += this.curTouchPosVel.x;
                this.curTouchPos.y += this.curTouchPosVel.y;
            }

            this.touchSpeed = Math.sqrt(this.curTouchPosVel.y * this.curTouchPosVel.y + this.curTouchPosVel.x * this.curTouchPosVel.x);

            this.touchSpeedVel *= SceneConfig.TOUCH_SPEED_DECEL;
            this.touchSpeedVel += (this.touchSpeed - this.curTouchSpeed) * SceneConfig.TOUCH_SPEED_ACCEL;
            this.curTouchSpeed += this.touchSpeedVel;

            this.renderShader.uniforms.touchPos.value.x = 1.0 - this.curTouchPos.x;
            this.renderShader.uniforms.touchPos.value.y = this.curTouchPos.y;
            this.renderShader.uniforms.touchSpeed.value = this.curTouchSpeed;


            if (!SceneConfig.NO_PERTURB) {
                let targetDistortionAmount = this.curTouchSpeed * SceneConfig.DISORTION_AMOUNT_MULTIPLIER;

                if (targetDistortionAmount > this.targetDistortionAmount) {
                    this.targetDistortionAmount = targetDistortionAmount;
                } else {
                    this.targetDistortionAmount *= SceneConfig.DISORTION_AMOUNT_DECAY;
                }
                // this.targetDistortionAmount = this.curTouchSpeed * SceneConfig.DISORTION_AMOUNT_MULTIPLIER;
                this.distortionAmountVel *= SceneConfig.DISORTION_AMOUNT_DECEL;
                this.distortionAmountVel += (this.targetDistortionAmount - this.curDisortionAmount) * SceneConfig.DISORTION_AMOUNT_ACCEL;
                this.curDisortionAmount += this.distortionAmountVel;


                this.renderShader.uniforms.distortionAmount.value = this.targetDistortionAmount; // this.curDisortionAmount;
            }


            const colorMoveTime: number = this.time * SceneConfig.COLOR_REVOLVE_SPEED;
            const color2MoveTime: number = colorMoveTime * SceneConfig.COLOR_2_SPEED;

            const rotationPhase: number = SceneConfig.COLOR_REVOLVE_PHASE * Math.PI * 2;

            this.renderShader.uniforms.colorPos1.value.x = Math.cos(colorMoveTime + rotationPhase) * SceneConfig.COLOR_1_DIST;
            this.renderShader.uniforms.colorPos1.value.y = Math.sin(colorMoveTime + rotationPhase) * SceneConfig.COLOR_1_DIST;

            this.renderShader.uniforms.colorPos2.value.x = Math.cos(color2MoveTime + rotationPhase + SceneConfig.COLOR_2_PHASE * Math.PI * 2) * SceneConfig.COLOR_2_DIST;
            this.renderShader.uniforms.colorPos2.value.y = Math.sin(color2MoveTime + rotationPhase + SceneConfig.COLOR_2_PHASE * Math.PI * 2) * SceneConfig.COLOR_2_DIST;

            let shiftX: number = 0;
            let shiftY: number = 0;
            let offsetX: number = 0;
            let offsetY: number = 0;

            if (!SceneConfig.MANUAL_SELECT) {
                this.targetPatternPosition = this.scrollIndex;

                this.patternPositionVel += (this.targetPatternPosition - this.curPatternPosition) * SceneConfig.TRANSITION_MOVE_ACCEL;
                this.patternPositionVel *= SceneConfig.TRANSITION_MOVE_DECEL;

                if (Math.abs(this.targetPatternPosition - this.curPatternPosition) < 0.001 && Math.abs(this.patternPositionVel) < 0.0001) { // math floor issues when number nears next integer
                    this.patternPositionVel = 0;
                    this.curPatternPosition = this.targetPatternPosition;
                } else {
                    // if (this.patternPositionVel > SceneConfig.OFFSET_SHIFT_MAX_VEL) {
                    //     this.patternPositionVel = SceneConfig.OFFSET_SHIFT_MAX_VEL;
                    // } else if (this.patternPositionVel < -SceneConfig.OFFSET_SHIFT_MAX_VEL) {
                    //     this.patternPositionVel = -SceneConfig.OFFSET_SHIFT_MAX_VEL;
                    // }

                    this.curPatternPosition += this.patternPositionVel;
                }
                this.updatePattern();


                const targetZoomSpeed = Math.min(1, Math.abs(this.patternPositionVel * 60));
                this.zoomSpeedVel += (targetZoomSpeed - this.zoomSpeed) * SceneConfig.ZOOM_SPEED_ACCEL;
                this.zoomSpeedVel *= SceneConfig.ZOOM_SPEED_DECEL;
                this.zoomSpeed += this.zoomSpeedVel;


                this.curTouchOffset.x = ((this.curTouchPos.x - 0.5) * 2.0);
                this.curTouchOffset.y = ((this.curTouchPos.y - 0.5) * 2.0);

                this.touchOffset.x = this.curTouchOffset.x * SceneConfig.OFFSET_SHIFT_RANGE - SceneConfig.OFFSET_SHIFT_RANGE_OFFSET;
                this.touchOffset.y = this.curTouchOffset.y * SceneConfig.OFFSET_SHIFT_RANGE - SceneConfig.OFFSET_SHIFT_RANGE_OFFSET;

                this.targetOffsetShift.x = this.touchOffset.x;
                this.targetOffsetShift.y = this.touchOffset.y;

                const curOffsetTime: number = SceneConfig.SHIFT_OFFSET_SPEED * this.time;

                this.targetOffsetShift.x += (Math.sin(curOffsetTime * 0.97) + Math.cos(curOffsetTime * 1.62)) / 2 * SceneConfig.SHIFT_OFFSET_AMPLITUDE;
                this.targetOffsetShift.y += (Math.cos(curOffsetTime * 1.13) + Math.sin(curOffsetTime * 1.2)) / 2 * SceneConfig.SHIFT_OFFSET_AMPLITUDE;

                this.targetOffsetShift.x = 1.0 / (1.0 + this.targetOffsetShift.x);
                this.targetOffsetShift.y = 1.0 / (1.0 + this.targetOffsetShift.y);

                this.offsetShiftVel.x += (this.targetOffsetShift.x - this.offsetShift.x) * SceneConfig.OFFSET_SHIFT_MOVE_ACCEL;
                this.offsetShiftVel.y += (this.targetOffsetShift.y - this.offsetShift.y) * SceneConfig.OFFSET_SHIFT_MOVE_ACCEL;
                this.offsetShiftVel.x *= SceneConfig.OFFSET_SHIFT_MOVE_DECEL;
                this.offsetShiftVel.y *= SceneConfig.OFFSET_SHIFT_MOVE_DECEL;

                this.offsetShift.x += this.offsetShiftVel.x;
                this.offsetShift.y += this.offsetShiftVel.y;

                this.renderShader.uniforms.offsetShift.value.x = 0;//this.offsetShift.x;
                this.renderShader.uniforms.offsetShift.value.y = 0;//this.offsetShift.y;


                this.targetCenterShift.x = this.touchOffset.x;
                this.targetCenterShift.y = this.touchOffset.y;

                this.targetCenterShift.x += (Math.cos(curOffsetTime * 1.56) + Math.sin(curOffsetTime * 1.83)) / 2 * SceneConfig.CENTER_SHIFT_AMPLITUDE;
                this.targetCenterShift.y += (Math.sin(curOffsetTime * 0.74) + Math.cos(curOffsetTime * 1.62)) / 2 * SceneConfig.CENTER_SHIFT_AMPLITUDE;

                this.centerShiftVel.x += (this.targetCenterShift.x - this.centerShift.x) * SceneConfig.OFFSET_SHIFT_MOVE_ACCEL;
                this.centerShiftVel.y += (this.targetCenterShift.y - this.centerShift.y) * SceneConfig.OFFSET_SHIFT_MOVE_ACCEL;
                this.centerShiftVel.x *= SceneConfig.OFFSET_SHIFT_MOVE_DECEL;
                this.centerShiftVel.y *= SceneConfig.OFFSET_SHIFT_MOVE_DECEL;

                this.centerShift.x += this.centerShiftVel.x;
                this.centerShift.y += this.centerShiftVel.y;

                this.renderShader.uniforms.centerShift.value.x = this.centerShift.x * SceneConfig.CENTER_SHIFT_AMOUNT;
                this.renderShader.uniforms.centerShift.value.y = this.centerShift.y * SceneConfig.CENTER_SHIFT_AMOUNT;

                if (this.absTouchSpeed > SceneConfig.VIEW_OFFSET_MIN_TOUCH_SPEED) { // !Config.MOBILE || this.pressed) {
                    this.viewOffsetTarget.x = this.curTouchOffset.x;
                    this.viewOffsetTarget.y = this.curTouchOffset.y;
                } else {
                    this.viewOffsetTarget.x *= SceneConfig.VIEW_OFFSET_MOVE_RESTORE;
                    this.viewOffsetTarget.y *= SceneConfig.VIEW_OFFSET_MOVE_RESTORE;
                }

                this.viewOffsetVel.x += (this.viewOffsetTarget.x - this.viewOffset.x) * SceneConfig.VIEW_OFFSET_MOVE_ACCEL;
                this.viewOffsetVel.y += (this.viewOffsetTarget.y - this.viewOffset.y) * SceneConfig.VIEW_OFFSET_MOVE_ACCEL;
                this.viewOffsetVel.x *= SceneConfig.VIEW_OFFSET_MOVE_DECEL;
                this.viewOffsetVel.y *= SceneConfig.VIEW_OFFSET_MOVE_DECEL;

                this.viewOffset.x += this.viewOffsetVel.x;
                this.viewOffset.y += this.viewOffsetVel.y;

                this.renderShader.uniforms.viewOffset.value.x = this.viewOffset.x * SceneConfig.VIEW_OFFSET_AMOUNT;
                this.renderShader.uniforms.viewOffset.value.y = -this.viewOffset.y * SceneConfig.VIEW_OFFSET_AMOUNT;
            } else {
                const waveX: number = ((this.curTouchPos.x - 0.5) * 2.0) * SceneConfig.CHLADNI_RANGE;
                const waveY: number = ((this.curTouchPos.y - 0.5) * 2.0) * SceneConfig.CHLADNI_RANGE;

                shiftX = Math.abs(Math.round(waveX));
                shiftY = Math.abs(Math.round(waveY));

                offsetX = Math.round(Math.abs(waveX) * 4) % 4 / 4;
                offsetY = Math.round(Math.abs(waveY) * 4) % 4 / 4;

                if (Math.abs(shiftX) === Math.abs(shiftY)) {
                    shiftY++;
                }

                this.targetShift.x = shiftX;
                this.targetShift.y = shiftY;
                this.targetShiftOffset.x = offsetX;
                this.targetShiftOffset.y = offsetY;

                this.renderShader.uniforms.shiftA.value.x = shiftX * this.PI;
                this.renderShader.uniforms.shiftA.value.y = shiftY * this.PI;

                this.renderShader.uniforms.offsetA.value.x = offsetX;
                this.renderShader.uniforms.offsetA.value.y = offsetY;

                console.log('chladni shape: [' + this.curShiftX + ', ' + this.curShiftY + ', ' + this.curOffsetX + ', ' + this.curOffsetY + ']');
            }
        }
    }

}

export default new SceneModel();