import { useEffect } from 'react'
import { gsap } from 'gsap'

export const useTransition = (ref, { isDarkened, isVisible, selectors }) => {
  const { darken } = selectors

  useEffect(() => {
    const $root = ref.current

    if (isVisible) {
      gsap.to($root, { opacity: 1, duration: 1 })
    } else {
      gsap.to($root, { opacity: 0, duration: 1 })
    }
  }, [ref, isVisible])

  useEffect(() => {
    let tl
    const $darken = ref.current.querySelector(darken)

    if (isDarkened) {
      tl = gsap.to($darken, {
        opacity: 1,
        delay: 0.5,
        duration: 0.75,
        overwrite: true,
      })
    } else {
      tl = gsap.to($darken, {
        opacity: 0,
        delay: 0.5,
        duration: 1,
        overwrite: true,
      })
    }

    return () => {
      if (tl) tl.kill()
    }
  }, [ref, isDarkened, darken])
}
