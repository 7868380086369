// pulled from stage.html
// example: https://github.com/react-spring/react-three-fiber/blob/master/examples/src/demos/dev/ShaderMaterial.js

const vertexShader = `
  varying vec2 vUv;
  void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`

const fragmentShader = `
  precision highp float;

  varying vec2 vUv;

  uniform float viewWidthRatio;

  uniform float noiseIntensity;

  uniform float time;
  uniform float zoom;
  uniform float patternZoom;

  uniform vec2 touchPos;
  uniform float touchSpeed;

  uniform vec2 colorPos1;
  uniform vec2 colorPos2;

  uniform vec3 color1;
  uniform vec3 color2;

  uniform float colorSize1;
  uniform float colorSize2;
  uniform float colorIntensity1;
  uniform float colorIntensity2;

  uniform vec2 viewOffset;

  uniform vec2 offsetShift;
  uniform vec2 centerShift;

  uniform vec2 shiftA;
  uniform vec2 offsetA;
  uniform float zoomA;

  uniform vec2 shiftB;
  uniform vec2 offsetB;
  uniform float zoomB;

  uniform float curPosition;

  uniform float waveBlendAmount;


  float saturateValue(float value) {
    return clamp(value, 0.0, 1.0);
  }

  float sinBlend(float amount) {
      return 0.5 - 0.5 * cos(amount * 3.14159265);
  }

  float rand(vec2 co){
      return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
  }

  float getMeshShaderSegmentIntensity(float radialPosition, float dist) {
    float intensityLine = 0.0;
    
    float ringStart = 0.08; //TODO: UNIFORM
    float ringThickness = 0.12; //TODO: UNIFORM
    float ringOffet = 2.0; //TODO: UNIFORM
    float ringAngleStart = 0.0; //TODO: UNIFORM
    float ringAngleRange = 0.9; //TODO: UNIFORM
    float ringSharpness = 15.0; //TODO: UNIFORM
    float gridScaleX = 1.0; //TODO: UNIFORM
    float gridScaleY = 1.0; //TODO: UNIFORM
    float ringGridXCount = 15.0; //TODO: UNIFORM
    float ringGridYCount = 120.0; //TODO: UNIFORM
    float ringXSpeed = 0.08; //TODO: UNIFORM
    float ringYSpeed = 0.1; //TODO: UNIFORM
    float ringBulge = 0.4; //0.5; //TODO: UNIFORM

    radialPosition = fract(radialPosition);
    
    float curRingEnd = (radialPosition - ringAngleStart) / ringAngleRange;
    curRingEnd = saturate(curRingEnd);
    
    ringStart += curRingEnd * ringThickness * ringOffet;

    curRingEnd = sin(curRingEnd * 3.14159) * ringThickness;

    float ringThicknessProgress = (dist - ringStart) / curRingEnd;
    ringThicknessProgress = saturate(ringThicknessProgress);
    

    float meshYIntensitySquashAmount = 1.0 - ringBulge * sin(ringThicknessProgress * 3.14159);
    float meshYIntensityBendAmount = (ringThicknessProgress - 0.5) * meshYIntensitySquashAmount + 0.5;

    float meshEdgeIntensity = 1.0 - 0.5 * sin(ringThicknessProgress * 3.14159);
    meshEdgeIntensity = saturate(meshEdgeIntensity);
    meshEdgeIntensity = min((meshEdgeIntensity - 0.92) * ringSharpness, 1.0);
    meshEdgeIntensity = saturate(meshEdgeIntensity);
    intensityLine = max(intensityLine, meshEdgeIntensity);

    float meshRadialYOffset = meshYIntensityBendAmount - time * ringYSpeed;
    float meshYIntensity = 0.5 + 0.5 * sin(meshRadialYOffset * 6.28318 * ringGridXCount);
    meshYIntensity = saturate(meshYIntensity);
    meshYIntensity = min((meshYIntensity - 0.9) * ringSharpness, 1.0);
    meshYIntensity = saturate(meshYIntensity);
    intensityLine = max(intensityLine, meshYIntensity); // meshYIntensityBendAmount; // 

    float meshRadialXOffset = radialPosition - time * ringXSpeed;
    float meshXIntensity = 0.5 + 0.5 * sin(meshRadialXOffset * 6.28318 * ringGridYCount);
    meshXIntensity = saturate(meshXIntensity);
    meshXIntensity = min((meshXIntensity - 0.9) * ringSharpness, 1.0);
    meshXIntensity = saturate(meshXIntensity);
    intensityLine = max(intensityLine, meshXIntensity);

    float ringAmount = sinBlend(ringThicknessProgress * 2.0);
    ringAmount = min(pow(ringAmount, 0.4) * ringSharpness, 1.0);
    intensityLine *= ringAmount;

    return intensityLine;
  }

  void main() {
      float PI = 3.14159265359;

      vec4 outputColor = vec4(1.0, 1.0, 1.0, 1.0);

      vec2 aspectRatio = mix(vec2(viewWidthRatio, 1.0), vec2(1.0, 1.0 / viewWidthRatio), 0.5);

      vec2 touchDelta = (touchPos - vUv) * aspectRatio;
      float touchDist = sqrt(touchDelta.x * touchDelta.x + touchDelta.y * touchDelta.y);
      touchDist = (pow(1.0 - saturateValue(touchDist / 0.9), 5.0) * saturateValue(touchSpeed * 1.5) * 100.0) * 0.7;


      // vec2 coord = (vUv - 0.5) * aspectRatio * zoom;
      vec2 coordOrigin = (vUv - 0.5) * aspectRatio;


      float dist = sqrt(coordOrigin.x * coordOrigin.x + coordOrigin.y * coordOrigin.y);
      float offsetDist = pow(dist, 0.5);

      float radialPosition = (atan(coordOrigin.x, coordOrigin.y) + 3.14159) / 6.28318;

      // vec2 coord = vec2(0.0);
      // coord.x = radialPosition;
      // coord.y = offsetDist - time * 0.035;

      // // coord *= zoom;
      

      // vec2 baseCoord = coord;

      // coord += viewOffset;

      // coord *= patternZoom;


      float ringSpinSpeed = -0.05; //TODO: UNIFORM


      radialPosition = radialPosition - time * ringSpinSpeed;


      float intensityLine = 0.0; // 1.0; // 0.5 + 0.5 * sin(radialOffset * 200.0);


      float radialPosition2 = radialPosition + 0.5;

      intensityLine = max(intensityLine, getMeshShaderSegmentIntensity(radialPosition, dist));
      intensityLine = max(intensityLine, getMeshShaderSegmentIntensity(radialPosition2, dist));

      // float spiralOffsetAmount = radialPosition * 0.25;
      
      // float radialOffset = offsetDist - time * 0.02 - curPosition * 0.2;
      


      // float circleAmount = 0.5 + 0.5 * sin(radialOffset * 40.0);
      // circleAmount = min(pow(circleAmount, 0.4) * 20.0, 1.0);
      // intensityLine *= circleAmount;

      // float spiralArmAmount = 0.5 + 0.5 * sin((radialPosition - curPosition * 2.0 - time * 0.3 + offsetDist * 5.0) * 2.0 * 3.1415926535897932384626433832795);
      // spiralArmAmount = min(pow(spiralArmAmount, 0.4) * 20.0, 1.0);
      // intensityLine *= spiralArmAmount;


      // darkening
      // intensityLine *= pow(min(dist * 4.0, 1.0), 2.0);
      intensityLine *= pow(min(dist * 1.5, 1.0), 0.5);


      // intensityLine *= touchDist;
      

      outputColor.rgb = vec3(intensityLine);


      // float noiseValue = rand(baseCoord * 13.7 + time);
      // noiseValue *= noiseValue;
      // noiseValue = 1.0 - noiseValue * noiseIntensity; // 0.18
      // outputColor.rgb *= noiseValue;

      // debug
      // gl_FragColor = vec4(coord.x, coord.y, 0.0, 1.0);

      gl_FragColor = outputColor;
      
  }
`

export { vertexShader, fragmentShader }
export default {}; // export default so gatsby doesn't complain for react components on npm: build