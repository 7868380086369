import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import PageVisibility from 'react-page-visibility'
import { useRecoilState } from 'recoil'
import { mutedState } from '@/store'

/**
 * Handle page visibility changes (changing tabs)
 */

const PageVisibilityService = ({ children }) => {
  const [isMuted, setIsMuted] = useRecoilState(mutedState)
  const prevMutedState = useRef(true)

  const handleChange = (isVisible) => {
    if (isVisible && !prevMutedState.current) {
      setIsMuted(false)
    } else if (!isVisible) {
      prevMutedState.current = isMuted
      setIsMuted(true)
    }
  }

  return <PageVisibility onChange={handleChange}>{children}</PageVisibility>
}

PageVisibilityService.propTypes = {
  children: PropTypes.node,
}

export { PageVisibilityService }
