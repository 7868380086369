export class Config {
    // ?dev query string reports out current chladni pattern code in browser console
    public readonly DEV: boolean = typeof window !== 'undefined' && (window.location.search.indexOf('dev') !== -1);

    public readonly DEBUG: boolean = typeof window !== 'undefined' && (window.location.search.indexOf('debug') !== -1);

    public readonly MOBILE: boolean = typeof navigator !== 'undefined' && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    public readonly RETINA_MODE: boolean = typeof window !== 'undefined' && (window.location.search.indexOf('retina') !== -1);
    public readonly RETINA_MED_MODE: boolean = typeof window !== 'undefined' && (window.location.search.indexOf('med') !== -1);

}

export default new Config();