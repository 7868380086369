import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { SoundEffect } from '@/components/SoundEffect'

const SoundHover = ({ children, hasHover, url }) => {
  const [hasHovered, setHasHovered] = useState(false)
  const soundPlayer = useRef({})

  const handleHover = () => {
    if (hasHover) {
      setHasHovered(true)
      soundPlayer.current.player.seekTo(0)
    }
  }

  if (url) {
    return (
      <div>
        <SoundEffect ref={soundPlayer} url={url} isPlaying={hasHovered} />
        <div onMouseEnter={handleHover} role="presentation">
          {children}
        </div>
      </div>
    )
  }

  return children
}

SoundHover.defaultProps = {
  hasHover: true,
}

SoundHover.propTypes = {
  children: PropTypes.node,
  hasHover: PropTypes.bool,
  url: PropTypes.string,
}

export { SoundHover }
