import { atom } from 'recoil'

/**
 * Manage the state of the project music theme
 *
 * @usage
 * const isProjectMusicPlaying = useRecoilValue(projectMusicState)
 */

export const projectMusicState = atom({
  key: 'projectMusicState', // unique ID (with respect to other atoms/selectors)
  default: false,
})
