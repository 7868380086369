import { useEffect } from 'react'
import { useLocation } from '@reach/router'
import { useSetRecoilState } from 'recoil'
import { previewState } from '@/store/previewState'

/**
 * Store global state based on Storyblok preview data detection
 */

const PreviewService = () => {
  const setState = useSetRecoilState(previewState)
  const { search } = useLocation()

  useEffect(() => {
    if (search?.includes('_storyblok')) {
      setState(true)
    } else {
      setState(false)
    }
  }, [search, setState])

  return null
}

export { PreviewService }
