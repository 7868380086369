// extracted by mini-css-extract-plugin
export var Header = "F_bD";
export var aniSpin = "F_d";
export var contact = "F_bV";
export var logo = "F_bF";
export var logo__link = "F_bG";
export var nav = "F_bH";
export var nav__item = "F_bM";
export var nav__list = "F_bL";
export var nav__primary = "F_bJ";
export var nav__secondary = "F_bK";
export var nav__underline = "F_bN";
export var social = "F_bP";
export var social__item = "F_bS";
export var social__label = "F_bQ";
export var social__link = "F_bT";
export var social__links = "F_bR";