import { atom } from 'recoil'

/**
 * Store audio muted state here
 *
 * @usage
 * const [isMuted, setIsMuted] = useRecoilValue(mutedState)
 */

export const mutedState = atom({
  key: 'mutedState', // unique ID (with respect to other atoms/selectors)
  default: false,
})
