import React from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import {
  readyState,
  mutedState,
  defaultMusicState,
  projectMusicState,
} from '@/store'
import { Music } from '@/components/Music'

const MUSIC_FADE_DURATION = 2000

const MusicService = ({ defaultSrc, projectSrc }) => {
  const isReady = useRecoilValue(readyState)
  const isMuted = useRecoilValue(mutedState)
  const isPlayingDefaultMusic = useRecoilValue(defaultMusicState)
  const isPlayingProjectMusic = useRecoilValue(projectMusicState)

  return (
    <div>
      {defaultSrc && (
        <Music
          url={defaultSrc}
          isPlaying={isReady}
          volume={isPlayingDefaultMusic ? 1 : 0}
          fadeDuration={MUSIC_FADE_DURATION}
          isMuted={isMuted}
        />
      )}
      {projectSrc && (
        <Music
          url={projectSrc}
          isPlaying={isReady}
          volume={isPlayingProjectMusic ? 1 : 0}
          fadeDuration={MUSIC_FADE_DURATION}
          isMuted={isMuted}
        />
      )}
    </div>
  )
}

MusicService.propTypes = {
  defaultSrc: PropTypes.string,
  projectSrc: PropTypes.string,
}

export { MusicService }
