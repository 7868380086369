import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './BtnMenu.styles.scss'

const BtnMenu = ({ isActive, onClick }) => {
  const handleOnClick = () => {
    if (typeof onClick === 'function') onClick()
  }

  return (
    <button
      type="button"
      className={styles.BtnMenu}
      aria-label="Toggle Menu"
      onClick={handleOnClick}
      data-active={isActive}
    >
      <div className={styles.menu}>
        <div className={styles.menu__line} />
        <div className={styles.menu__line} />
        <div className={styles.menu__line} />
      </div>
      <div className={styles.close}>
        <div className={styles.close__line} />
        <div className={styles.close__line} />
      </div>
    </button>
  )
}

BtnMenu.defaultProps = {
  isActive: false,
  onClick: () => {},
}

BtnMenu.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}

export { BtnMenu }
