import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useRecoilValue, useRecoilState } from 'recoil'
import { SliderButton } from '@typeform/embed-react'
import { browserState, menuState } from '@/store'
import { RichText } from '@/components/RichText'
import { BtnMenu } from '@/components/BtnMenu'
import TransitionService from '@/services/TransitionService'
import * as styles from './Menu.styles.scss'
import { useTransition } from './Menu.gsap'

const Menu = ({ nav, info, infoLink, socialLinks, typeformId }) => {
  const { viewportHeight } = useRecoilValue(browserState)
  const ref = useRef()
  const [isOpen, setIsOpen] = useRecoilState(menuState)

  useTransition(ref, {
    selectors: {
      bg: `.${styles.bg}`,
      content: `.${styles.content}`,
    },
    isOpen,
  })

  const handleClose = () => setIsOpen(false)
  const handleToggle = () => setIsOpen((prev) => !prev)

  const handleNavLinkClick = (url) => {
    setIsOpen(false)

    if (url) {
      setTimeout(() => {
        // navigate(url)

        if (TransitionService.ready) {
          TransitionService.in(url)
            .then(() => {
              navigate(url)
            })
            .catch((error) => console.warn(error))
        }
      }, 500)
    }
  }

  return (
    <div className={styles.Menu} data-open={isOpen} ref={ref}>
      <div className={styles.toggle}>
        <BtnMenu isActive={isOpen} onClick={handleToggle} />
      </div>
      <div
        className={styles.panel}
        data-open={isOpen}
        style={{ height: `${viewportHeight}px` }}
      >
        {isOpen && (
          <button
            type="button"
            className={styles.closeBg}
            onClick={handleClose}
          >
            Close Menu
          </button>
        )}
        <div className={styles.container}>
          <div className={styles.bg} />
          <div
            className={styles.content}
            style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
          >
            <ul className={styles.nav}>
              {nav.map((item) => (
                <li key={item.uid}>
                  <button
                    type="button"
                    onClick={() => handleNavLinkClick(item.url)}
                  >
                    {item.title}
                  </button>
                </li>
              ))}
              {typeformId && (
                <li>
                  <SliderButton id={typeformId}>Contact</SliderButton>
                </li>
              )}
            </ul>
            <div className={styles.info}>
              {info && (
                <div className={styles.info__text}>
                  <RichText>{info}</RichText>
                </div>
              )}
              {infoLink && infoLink.url && infoLink.title && (
                <div className={styles.info__cta}>
                  <a href={infoLink.url} className={styles.info__cta__link}>
                    {infoLink.title}
                  </a>
                </div>
              )}
            </div>
            <div className={styles.social}>
              {!!socialLinks.length && (
                <div>
                  {socialLinks.map((item) => (
                    <div key={item.uid}>
                      <a href={item.url} target="_blank" rel="noreferrer">
                        {item.title}
                      </a>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Menu.defaultProps = {
  nav: [],
  socialLinks: [],
}

Menu.propTypes = {
  info: PropTypes.node,
  infoLink: PropTypes.object,
  nav: PropTypes.arrayOf(PropTypes.object),
  socialLinks: PropTypes.arrayOf(PropTypes.object),
  typeformId: PropTypes.string,
}

export { Menu }
