/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

const TransitionService = require('@/services/TransitionService').default

exports.onRouteUpdate = () => {
  TransitionService.out()
}
