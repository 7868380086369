// import SceneConfig from '../resources/SceneConfig'

import React, { useRef, useEffect } from 'react'
// import * as THREE from 'three'
import { extend, useThree, useFrame } from '@react-three/fiber'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass'
import { RadialBlurPass } from '../resources/RadialBlurPass'

// newer version of EffectComposer?
// import { EffectComposer } from '@react-three/postprocessing'

// import SceneModel from '../resources/SceneModel.ts'

// import { lerp } from '../resources/MathUtil.ts'

extend({
  EffectComposer,
  ShaderPass,
  RenderPass,
  UnrealBloomPass,
  FilmPass,
  RadialBlurPass,
})

export default function Effects() {
  const composer = useRef()
  const { scene, gl, size, camera } = useThree()

  // const radialBlurPass = useRef()

  useEffect(() => {
    composer.current.setSize(size.width, size.height)
  }, [size])

  useFrame(() => {
    composer.current.render()

    // const zoomSpeed = SceneModel.zoomSpeed

    // const blurDistance = lerp(
    //   SceneConfig.RADIAL_BLUR_DIST_BASE,
    //   SceneConfig.RADIAL_BLUR_DIST,
    //   zoomSpeed
    // )
    // const blurIntensity = lerp(
    //   SceneConfig.RADIAL_BLUR_INTENSITY_BASE,
    //   SceneConfig.RADIAL_BLUR_INTENSITY,
    //   zoomSpeed
    // )
    // const originalIntensity = lerp(
    //   SceneConfig.RADIAL_BLUR_ORIG_INTENSITY,
    //   SceneConfig.RADIAL_BLUR_ORIG_INTENSITY_REDUCED,
    //   zoomSpeed
    // )
    // const radialEdgeBrightnessReduction = lerp(
    //   SceneConfig.RADIAL_BLUR_EDGE_BRIGHTNESS,
    //   SceneConfig.RADIAL_BLUR_EDGE_BRIGHTNESS_REDUCED,
    //   zoomSpeed
    // )

    // radialBlurPass.current.uniforms.blurDistance.value = blurDistance
    // radialBlurPass.current.uniforms.blurIntensity.value = blurIntensity
    // radialBlurPass.current.uniforms.originalIntensity.value = originalIntensity
    // radialBlurPass.current.uniforms.radialEdgeBrightnessReduction.value =
    //   radialEdgeBrightnessReduction
  }, 2)

  return (
    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray="passes" scene={scene} camera={camera} />
      {/* <unrealBloomPass
        attachArray="passes"
        args={[new THREE.Vector2(window.innerWidth, window.innerHeight), SceneConfig.SCENE_GLOW_STRENGTH, SceneConfig.SCENE_GLOW_RADIUS, SceneConfig.SCENE_GLOW_THRESHOLD]}
      /> */}
      {/* <filmPass
        attachArray="passes"
        //noiseIntensity?: number, scanlinesIntensity?: number, scanlinesCount?: number, grayscale?: number
        args={[2, 0.1, 500, 0]}
      /> */}
      {/* <radialBlurPass
        ref={radialBlurPass}
        attachArray="passes"
        args={[0, 0, 0, SceneConfig.CENTER_DARKENING]}
      /> */}
    </effectComposer>
  )
}
