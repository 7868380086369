import React from 'react'
import PropTypes from 'prop-types'
import { BtnAudioToggle } from '@/components/BtnAudioToggle'
import { SoundHover } from '@/components/SoundHover'
import * as styles from './Footer.styles.scss'

const Footer = ({ hoverSound }) => {
  return (
    <div className={styles.Footer}>
      <div className={styles.gradient} />
      <div className={styles.audio}>
        <SoundHover url={hoverSound}>
          <BtnAudioToggle />
        </SoundHover>
      </div>
    </div>
  )
}

Footer.propTypes = {
  hoverSound: PropTypes.string,
}

export { Footer }
