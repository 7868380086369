import React, { useMemo, useRef } from 'react'
import { useThree, useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import SceneConfig from './SceneConfig.ts'
// import { vertexShader, fragmentShader } from './Shader'
import { vertexShader as vertexShaderMeshEffect, fragmentShader as fragmentShaderMeshEffect } from './ShaderMeshEffect'
import { vertexShader as vertexShaderMeshEffectV1, fragmentShader as fragmentShaderMeshEffectV1 } from './ShaderMeshEffectV1'
import { vertexShader as vertexShaderMeshEffectV2, fragmentShader as fragmentShaderMeshEffectV2 } from './ShaderMeshEffectV2'
import { vertexShader as vertexShaderMeshEffectV3, fragmentShader as fragmentShaderMeshEffectV3 } from './ShaderMeshEffectV3'
import { vertexShader as vertexShaderMeshEffectV4, fragmentShader as fragmentShaderMeshEffectV4 } from './ShaderMeshEffectV4'
import { vertexShader as vertexShaderRings, fragmentShader as fragmentShaderRings } from './ShaderRings'
import { vertexShader as vertexShaderRingsV1, fragmentShader as fragmentShaderRingsV1 } from './ShaderRingsV1'
import { vertexShader as vertexShaderV1, fragmentShader as fragmentShaderV1 } from './ShaderV1'
import { vertexShader as vertexShaderV2, fragmentShader as fragmentShaderV2 } from './ShaderV2'
import { vertexShader as vertexShaderV3, fragmentShader as fragmentShaderV3 } from './ShaderV3'
import { vertexShader as vertexShaderV4, fragmentShader as fragmentShaderV4 } from './ShaderV4'
import { vertexShader as vertexShaderV5, fragmentShader as fragmentShaderV5 } from './ShaderV5'
import { vertexShader as vertexShaderV6, fragmentShader as fragmentShaderV6 } from './ShaderV6'
import { vertexShader as vertexShaderNoLine, fragmentShader as fragmentShaderNoLine } from './ShaderNoLine'
import { vertexShader as vertexShaderNoLineV1, fragmentShader as fragmentShaderNoLineV1 } from './ShaderNoLineV1'
import { vertexShader as vertexShaderNoLineV2, fragmentShader as fragmentShaderNoLineV2 } from './ShaderNoLineV2'
import SceneModel from './SceneModel.ts'

const PI = 3.14159265359

const Mesh = () => {
  const ref = useRef() // give us direct access to the mesh

  // call  every frame, this is outside of React without overhead
  useFrame(({ clock }) => {
    SceneModel.update(clock.getElapsedTime())
  })

  const { gl, invalidate, viewport, camera } = useThree()

  camera.updateProjectionMatrix() // need to call initially to ensure othographic camera view frustrum updated correctly

  let curVertexShader = vertexShaderMeshEffect; // vertexShader;
  let curFragmentShader = fragmentShaderMeshEffect; // fragmentShader;

  // if (SceneConfig.EFFECT_MESH) {
  if (!SceneConfig.EFFECT_CHLADNI) {
    curVertexShader = vertexShaderMeshEffect;
    curFragmentShader = fragmentShaderMeshEffect;
  }
  if (SceneConfig.EFFECT_MESH_V1) {
    curVertexShader = vertexShaderMeshEffectV1;
    curFragmentShader = fragmentShaderMeshEffectV1;
  }
  if (SceneConfig.EFFECT_MESH_V2) {
    curVertexShader = vertexShaderMeshEffectV2;
    curFragmentShader = fragmentShaderMeshEffectV2;
  }
  if (SceneConfig.EFFECT_MESH_V3) {
    curVertexShader = vertexShaderMeshEffectV3;
    curFragmentShader = fragmentShaderMeshEffectV3;
  }
  if (SceneConfig.EFFECT_MESH_V4) {
    curVertexShader = vertexShaderMeshEffectV4;
    curFragmentShader = fragmentShaderMeshEffectV4;
  }
  if (SceneConfig.EFFECT_RINGS) {
    curVertexShader = vertexShaderRings;
    curFragmentShader = fragmentShaderRings;
  }
  if (SceneConfig.EFFECT_RINGS_V1) {
    curVertexShader = vertexShaderRingsV1;
    curFragmentShader = fragmentShaderRingsV1;
  }
  if (SceneConfig.EFFECT_V1) {
    curVertexShader = vertexShaderV1;
    curFragmentShader = fragmentShaderV1;
  }
  if (SceneConfig.EFFECT_V2) {
    curVertexShader = vertexShaderV2;
    curFragmentShader = fragmentShaderV2;
  }
  if (SceneConfig.EFFECT_V3) {
    curVertexShader = vertexShaderV3;
    curFragmentShader = fragmentShaderV3;
  }
  if (SceneConfig.EFFECT_V4) {
    curVertexShader = vertexShaderV4;
    curFragmentShader = fragmentShaderV4;
  }
  if (SceneConfig.EFFECT_V5) {
    curVertexShader = vertexShaderV5;
    curFragmentShader = fragmentShaderV5;
  }
  if (SceneConfig.EFFECT_V6) {
    curVertexShader = vertexShaderV6;
    curFragmentShader = fragmentShaderV6;
  }
  if (SceneConfig.EFFECT_NO_LINES) {
    curVertexShader = vertexShaderNoLine;
    curFragmentShader = fragmentShaderNoLine;
  }
  if (SceneConfig.EFFECT_NO_LINES_V1) {
    curVertexShader = vertexShaderNoLineV1;
    curFragmentShader = fragmentShaderNoLineV1;
  }
  if (SceneConfig.EFFECT_NO_LINES_V2) {
    curVertexShader = vertexShaderNoLineV2;
    curFragmentShader = fragmentShaderNoLineV2;
  }

  const shader = useMemo(() => {
    return {
      uniforms: {
        time: { value: 1.0 },
        viewWidthRatio: {
          value:
            typeof window !== 'undefined'
              ? window.innerWidth / window.innerHeight
              : 0,
        },
        zoom: { value: SceneConfig.ZOOM },
        patternZoom: { value: SceneConfig.PATTERN_ZOOM },

        noiseIntensity: { value: SceneConfig.NOISE_INTENSITY },

        touchPos: { value: new THREE.Vector2() },
        touchSpeed: { value: 0 },

        colorPos1: { value: new THREE.Vector2(1, 0) },
        colorPos2: { value: new THREE.Vector2(-0.5, 0.7) },

        color1: { value: new THREE.Vector3(1, 0, 0) },
        color2: { value: new THREE.Vector3(1, 0, 1) },

        glowSharpness: { value: SceneConfig.GLOW_SHARPNESS },
        glowIntensity: { value: SceneConfig.GLOW_INTENSITY },

        colorIntensity1: { value: SceneConfig.COLOR_1_INTENSITY },
        colorIntensity2: { value: SceneConfig.COLOR_2_INTENSITY },
        colorSize1: { value: SceneConfig.COLOR_1_SIZE },
        colorSize2: { value: SceneConfig.COLOR_2_SIZE },

        viewOffset: { value: new THREE.Vector2() },

        offsetShift: { value: new THREE.Vector2() },
        centerShift: { value: new THREE.Vector2() },

        shiftA: { value: new THREE.Vector2(11 * PI, 12 * PI) },
        offsetA: { value: new THREE.Vector2(1, 1) },

        shiftB: { value: new THREE.Vector2(11 * PI, 12 * PI) },
        offsetB: { value: new THREE.Vector2(1, 1) },

        zoomA: { value: 1 },
        zoomB: { value: 1 },

        curPosition: { value: 0 },

        waveBlendAmount: { value: 0 },

        distortionAmount: { value: 0 },
      },
      vertexShader: curVertexShader,
      fragmentShader: curFragmentShader,
    }
  }, [gl, invalidate, viewport])

  SceneModel.renderShader = shader

  return (
    <mesh ref={ref} visible>
      <planeBufferGeometry attach="geometry" args={[1, 1, 1, 1]} />
      <shaderMaterial attach="material" args={[shader]} />
    </mesh>
  )
}

export { Mesh }
