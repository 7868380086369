// pulled from stage.html
// example: https://github.com/react-spring/react-three-fiber/blob/master/examples/src/demos/dev/ShaderMaterial.js

const vertexShader = `
  varying vec2 vUv;
  void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`

const fragmentShader = `
  precision highp float;

  varying vec2 vUv;

  uniform float viewWidthRatio;

  uniform float noiseIntensity;

  uniform float time;
  uniform float zoom;
  uniform float patternZoom;

  uniform vec2 touchPos;
  uniform float touchSpeed;

  uniform vec2 colorPos1;
  uniform vec2 colorPos2;

  uniform vec3 color1;
  uniform vec3 color2;

  uniform float colorSize1;
  uniform float colorSize2;
  uniform float colorIntensity1;
  uniform float colorIntensity2;
  
  uniform float glowSharpness;
  uniform float glowIntensity;

  uniform vec2 viewOffset;

  uniform vec2 offsetShift;
  uniform vec2 centerShift;

  uniform vec2 shiftA;
  uniform vec2 offsetA;
  uniform float zoomA;

  uniform vec2 shiftB;
  uniform vec2 offsetB;
  uniform float zoomB;

  uniform float curPosition;

  uniform float waveBlendAmount;

  uniform float distortionAmount;


  float saturateValue(float value) {
    return clamp(value, 0.0, 1.0);
  }

  float sinBlend(float amount) {
      return 0.5 - 0.5 * cos(amount * 3.14159265);
  }

  float rand(vec2 co){
      return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
  }

  float getRingThicknessProgress(float curRingProgress, float dist, float ringSharpness) {
    float ringStart = 0.12; //TODO: UNIFORM
    float ringThickness = 0.16; //TODO: UNIFORM
    float ringInnerOffsetPower = 3.5; //TODO: UNIFORM
    float ringOuterOffsetPower = 0.6; //TODO: UNIFORM
    float ringThicknessBulgeAmount = 0.08; //TODO: UNIFORM
    float ringThicknessBulgeOffsetPower = 3.0; //TODO: UNIFORM
    
    float curRingInnerPos = ringStart + pow(curRingProgress, ringInnerOffsetPower) * ringThickness;
    // curRingInnerPos += curRingProgress * ringThickness;

    float curRingOuterPos = ringStart + pow(curRingProgress, ringOuterOffsetPower) * ringThickness;
    curRingOuterPos += sin(pow(pow(curRingProgress, ringThicknessBulgeOffsetPower), 0.5) * 3.14159) * ringThicknessBulgeAmount;
    // float curRingOuterPos = sin(pow(curRingProgress, 0.5) * 3.14159) * ringThickness;
    // float curRingOuterPos = sin(curRingProgress * 3.14159) * ringThickness;
    
    // intensityLine = max(intensityLine, curRingProgress);

    float ringThicknessProgress = (dist - curRingInnerPos) / (curRingOuterPos - curRingInnerPos);
    ringThicknessProgress = saturate(ringThicknessProgress);
    // float ringThicknessProgress = (dist - curRingInnerPos) / curRingOuterPos;

    return ringThicknessProgress;
  }

  float getMeshShaderSegmentIntensity(float radialPosition, float dist, float lineScale, float animationTime) {
    //TODO: UNIFORMS
    float intensityLine = 0.0;

    float ringAngleStart = 0.0;
    float ringAngleRange = 1.0;
    float ringSharpness = 20.0 / lineScale;
    float lineYSharpness = 15.0 / lineScale;
    float lineXSharpness = 20.0 / lineScale;
    float ringEdgeSharpness = 25.0 / lineScale;
    float ringEdgeOffset = 1.0 - 0.08 * lineScale; //0.94;
    float ringEdgeGlowOffset = 1.0 - 0.3;
    float ringEdgeGlowIntensity = 1.0; // 2.0;
    float meshYLineOffset = 1.0 - 0.08 * lineScale; //0.92;
    float meshXLineOffset = 1.0 - 0.05 * lineScale; //0.95;
    float ringGridXCount = 16.0;
    float ringGridYCount = 60.0;
    float ringXSpeed = 0.08;
    float ringYSpeed = 0.1;
    float ringBulge = 0.4;
    float ringBulgeLineThicknessMultiplier = 0.2 * lineScale;
    float ringEndsLineThicknessMultiplier = 1.3 * lineScale;
    float ringEndsLineThicknessPower = 3.5;
    float oppositeProgressObscurePosition = 0.5;

    float lineCenterPower = 200.0;
    float lineStrongGlowPower = 5.0;
    float lineStrongGlowAmount = 0.4;
    float lineSoftGlowAmount = 0.12;
    float unbrightenNodeAmount = 0.75;

    radialPosition = fract(radialPosition);
    
    // float curRingProgress = (radialPosition - ringAngleStart) / ringAngleRange;
    // curRingProgress = saturate(curRingProgress);
    // // curRingProgress = pow(curRingProgress, 3.0);

    // float curRingProgressBulgeAmount = sin(curRingProgress * 3.14159);
    
    // float curRingInnerPos = ringStart + pow(curRingProgress, ringInnerOffsetPower) * ringThickness * ringScale;
    // // curRingInnerPos += curRingProgress * ringThickness * ringScale;

    // float curRingOuterPos = ringStart + pow(curRingProgress, ringOuterOffsetPower) * ringThickness * ringScale;
    // curRingOuterPos += sin(pow(pow(curRingProgress, ringThicknessBulgeOffsetPower), 0.5) * 3.14159) * ringThicknessBulgeAmount;
    // // float curRingOuterPos = sin(pow(curRingProgress, 0.5) * 3.14159) * ringThickness;
    // // float curRingOuterPos = sin(curRingProgress * 3.14159) * ringThickness;
    
    // // intensityLine = max(intensityLine, curRingProgress);

    // float ringThicknessProgress = (dist - curRingInnerPos) / (curRingOuterPos - curRingInnerPos);
    // ringThicknessProgress = saturate(ringThicknessProgress);
    // // float ringThicknessProgress = (dist - curRingInnerPos) / curRingOuterPos;

    float curRingProgress = (radialPosition - ringAngleStart) / ringAngleRange;
    curRingProgress = saturate(curRingProgress);
    float ringThicknessProgress = getRingThicknessProgress(curRingProgress, dist, ringSharpness);

    float curRingProgressBulgeAmount = sin(curRingProgress * 3.14159);
    
    float meshBulgeIntensity = sin(ringThicknessProgress * 3.14159);

    float meshYIntensitySquashAmount = 1.0 - ringBulge * meshBulgeIntensity;
    float meshYIntensityBendAmount = (ringThicknessProgress - 0.5) * meshYIntensitySquashAmount + 0.5;


    float meshEdgeIntensity = 1.0 - 0.5 * meshBulgeIntensity;
    meshEdgeIntensity = saturate(meshEdgeIntensity);
    meshEdgeIntensity = min((meshEdgeIntensity - ringEdgeOffset) * ringEdgeSharpness, 1.0);
    meshEdgeIntensity = saturate(meshEdgeIntensity);
    intensityLine = max(intensityLine, meshEdgeIntensity);

    float meshEdgeGlowIntensity = 1.0 - 0.5 * meshBulgeIntensity;
    meshEdgeGlowIntensity = saturate(meshEdgeGlowIntensity);
    meshEdgeGlowIntensity = min((meshEdgeGlowIntensity - ringEdgeGlowOffset) * ringEdgeGlowIntensity, 1.0);
    meshEdgeGlowIntensity = saturate(meshEdgeGlowIntensity);
    intensityLine = max(intensityLine, meshEdgeGlowIntensity);

    float meshRadialYOffset = meshYIntensityBendAmount - animationTime * ringYSpeed;
    float meshYIntensity = 0.5 + 0.5 * sin(meshRadialYOffset * 6.28318 * ringGridXCount);
    meshYIntensity = saturate(meshYIntensity);
    float ringYIntensityOffsetEnds = pow(1.0 - curRingProgressBulgeAmount, ringEndsLineThicknessPower) * ringEndsLineThicknessMultiplier; // / ((1.0 - curRingProgressBulgeAmount) * 10.0);
    // float ringYIntensityOffsetBulge = 1.0 / ((1.0 - meshBulgeIntensity) * ringBulgeLineThicknessMultiplier);
    float ringYIntensityOffsetBulge = (1.0 - meshBulgeIntensity) * ringBulgeLineThicknessMultiplier;
    float meshYIntensityBase = meshYIntensity;
    meshYIntensity = pow(meshYIntensityBase, lineCenterPower); // + meshYIntensityBase * lineSoftGlowAmount;
    // meshYIntensity = pow(meshYIntensityBase, lineCenterPower) + pow(meshYIntensityBase, lineStrongGlowPower) * lineStrongGlowAmount; // + meshYIntensityBase * lineSoftGlowAmount;
    // meshYIntensity = pow(meshYIntensity, lineCenterPower) + meshYIntensity * lineSoftGlowAmount;
    // meshYIntensity = min((meshYIntensity - meshYLineOffset + ringYIntensityOffsetEnds + ringYIntensityOffsetBulge) * lineYSharpness, 1.0);
    meshYIntensity = saturate(meshYIntensity);
    intensityLine = max(intensityLine, meshYIntensity); // meshYIntensityBendAmount; // 

    // intensityLine = max(intensityLine, 1.0 - curRingProgressBulgeAmount);
    // intensityLine = max(intensityLine, (1.0 - curRingProgressBulgeAmount) * 10.0); // (1.0 - sinBlend(ringThicknessProgress * 2.0)));

    float meshRadialXOffset = radialPosition - animationTime * ringXSpeed;
    float meshXIntensity = 0.5 + 0.5 * sin(meshRadialXOffset * 6.28318 * ringGridYCount);
    meshXIntensity = saturate(meshXIntensity);
    float meshXIntensityBase = meshXIntensity;
    meshXIntensity = pow(meshXIntensityBase, lineCenterPower); // + meshXIntensityBase * lineSoftGlowAmount;
    // meshXIntensity = pow(meshXIntensityBase, lineCenterPower) + pow(meshXIntensityBase, lineStrongGlowPower) * lineStrongGlowAmount; // + meshXIntensityBase * lineSoftGlowAmount;
    // meshXIntensity = pow(meshXIntensity, lineCenterPower) + meshXIntensity * lineSoftGlowAmount;
    // meshXIntensity = min((meshXIntensity - meshXLineOffset) * lineXSharpness, 1.0);
    meshXIntensity = saturate(meshXIntensity);
    intensityLine = max(intensityLine, meshXIntensity);

    intensityLine += pow(meshXIntensityBase, lineStrongGlowPower) * lineStrongGlowAmount;
    intensityLine += pow(meshYIntensityBase, lineStrongGlowPower) * lineStrongGlowAmount;
    intensityLine -= pow(meshXIntensityBase, lineStrongGlowPower) * pow(meshYIntensityBase, lineStrongGlowPower) * lineStrongGlowAmount * unbrightenNodeAmount; // unbrighten nodes

    intensityLine += meshXIntensityBase * lineSoftGlowAmount;
    intensityLine += meshYIntensityBase * lineSoftGlowAmount;
    intensityLine -= meshXIntensityBase * meshYIntensityBase * lineSoftGlowAmount * unbrightenNodeAmount; // unbrighten nodes

    float ringAmount = sinBlend(ringThicknessProgress * 2.0);
    ringAmount = min(pow(ringAmount, 0.4) * ringSharpness, 1.0);
    intensityLine *= ringAmount;

    
    float curRingOppositeProgress = (fract(radialPosition + 0.5) - ringAngleStart) / ringAngleRange;
    curRingOppositeProgress = saturate(curRingOppositeProgress);
    curRingOppositeProgress *= 1.0 - step(radialPosition, oppositeProgressObscurePosition);
    float ringOppositeThicknessProgress = getRingThicknessProgress(curRingOppositeProgress, dist, ringSharpness);

    float ringObscureAmount = sinBlend(ringOppositeThicknessProgress * 2.0);
    ringObscureAmount = min(pow(ringObscureAmount, 0.4) * ringSharpness, 1.0);
    intensityLine *= 1.0 - ringObscureAmount;

    intensityLine = saturate(intensityLine);

    return intensityLine;
  }

  void main() {
      float PI = 3.14159265359;

      //TODO: UNIFORMS
      float ringSize = 3.0; // 1.8; // 1.75;
      float lineScale = 0.1; // 0.2;
      float ringPerspective = 0.6;
      float animationSpeed = 0.5;
      float ringSpinSpeed = -0.05;


      float ringInnerMaskStart = 0.136;
      float ringInnerMaskGlowStart = ringInnerMaskStart; // 0.135;
      float ringInnerMaskGlowDist = 0.015;
      float maskGlowAmount = 0.25; // 30.0;
      float ringInnerMaskEdgeThickness = 0.004 * lineScale;
      float maskSharpness = 600.0 / lineScale;

      float ringInnerLineOuterThickness = 0.0125;
      float lineCenterPower = 200.0;
      float lineStrongGlowPower = 5.0;
      float lineStrongGlowAmount = 0.4;
      float lineSoftGlowAmount = 0.12;

      
      float animationTime = time * animationSpeed - curPosition * 2.0;

      vec4 outputColor = vec4(1.0, 1.0, 1.0, 1.0);

      vec2 aspectRatio = mix(vec2(viewWidthRatio, 1.0), vec2(1.0, 1.0 / viewWidthRatio), 0.5);

      vec2 coordOrigin = (vUv - 0.5) * aspectRatio;

      
      vec2 touchDelta = (touchPos - vUv) * aspectRatio;
      float touchDist = sqrt(touchDelta.x * touchDelta.x + touchDelta.y * touchDelta.y);

      float touchDistDist = (pow(1.0 - saturateValue(touchDist / 1.0), 7.0) * saturateValue(touchSpeed * 1.5) * 100.0) * 1.8;
      float touchDistRadial = (pow(1.0 - saturateValue(touchDist / 1.2), 6.0) * saturateValue(touchSpeed * 1.5) * 100.0) * 2.1;

      
      vec2 coordOffsetDist = coordOrigin + touchDelta * touchDistDist;
      vec2 coordOffsetRadial = coordOrigin + touchDelta * touchDistRadial;


      float baseDist = sqrt(coordOrigin.x * coordOrigin.x + coordOrigin.y * coordOrigin.y);
      float baseRadialPosition = (atan(coordOrigin.x, coordOrigin.y) + 3.14159) / 6.28318;


      coordOffsetDist += sin(baseDist * 4.0 * 6.28318 + time * 2.0) * 0.002 * distortionAmount;
      coordOffsetDist += sin(baseRadialPosition * 3.0 * 6.28318 + time * 2.0) * 0.002 * distortionAmount;

      coordOffsetRadial += sin(baseDist * 2.3 * 6.28318 + time * 2.0) * 0.0015 * distortionAmount;
      coordOffsetRadial += sin(baseRadialPosition * 2.0 * 6.28318 + time * 2.0) * 0.01 * distortionAmount;


      float dist = sqrt(coordOffsetDist.x * coordOffsetDist.x + coordOffsetDist.y * coordOffsetDist.y);
      
      dist = pow(dist, ringPerspective);


      float radialPosition = (atan(coordOffsetRadial.x, coordOffsetRadial.y) + 3.14159) / 6.28318;


      radialPosition = radialPosition - animationTime * ringSpinSpeed;


      float intensityLine = 0.0; // 1.0; // 0.5 + 0.5 * sin(radialOffset * 200.0);


      float radialPosition2 = radialPosition + 0.5;

      float distScaled = dist / ringSize;

      intensityLine = max(intensityLine, getMeshShaderSegmentIntensity(radialPosition, distScaled, lineScale, animationTime));
      intensityLine = max(intensityLine, getMeshShaderSegmentIntensity(radialPosition2, distScaled, lineScale, animationTime));

      // float ringInnerEdgeAmount = (distScaled - ringInnerMaskStart - ringInnerMaskEdgeThickness) * maskSharpness;
      // intensityLine = max(intensityLine, 1.0 - ringInnerEdgeAmount);
  
      float ringInnerGlowAmount = 1.0 - saturate((distScaled - ringInnerMaskGlowStart) / ringInnerMaskGlowDist);
      intensityLine = max(intensityLine, ringInnerGlowAmount * maskGlowAmount);
      
      // float ringInnerEdgeAmount = (distScaled - ringInnerMaskStart - ringInnerMaskEdgeThickness) * maskSharpness;
      // intensityLine = max(intensityLine, 1.0 - ringInnerEdgeAmount);
  
      float ringInnerMaskAmount = (distScaled - ringInnerMaskStart) * maskSharpness;
      intensityLine *= saturate(ringInnerMaskAmount);

      float ringInnerLineAmount = sinBlend(saturate((distScaled - ringInnerMaskStart + ringInnerLineOuterThickness / 2.0) / ringInnerLineOuterThickness) * 2.0); // saturate(distScaled); // sinBlend(saturate(distScaled - ringInnerMaskStart / ringInnerMaskEdgeThickness));
      ringInnerLineAmount= pow(ringInnerLineAmount, lineCenterPower) + pow(ringInnerLineAmount, lineStrongGlowPower) * lineStrongGlowAmount + ringInnerLineAmount * lineSoftGlowAmount;
      intensityLine = max(intensityLine, ringInnerLineAmount);
      
      intensityLine = saturate(intensityLine);


      // darkening
      intensityLine *= pow(min(dist * 1.5, 1.0), 0.4);
      intensityLine *= pow(min(dist * 1.0, 1.0), 0.3);

      intensityLine = saturate(intensityLine);


      outputColor.rgb = vec3(intensityLine);


      
      
      // coloring
      vec2 centerCoord = vUv - 0.5;

      vec2 colorPoint1Delta = (colorPos1 - centerCoord) * aspectRatio;
      float colorPoint1Dist = saturateValue((1.0 - colorSize1 * sqrt(colorPoint1Delta.x * colorPoint1Delta.x + colorPoint1Delta.y * colorPoint1Delta.y)) * colorIntensity1);
      float color1Amount = colorPoint1Dist;


      vec2 colorPoint2Delta = (colorPos2 - centerCoord) * aspectRatio;
      float colorPoint2Dist = saturateValue((1.0 - colorSize2 * sqrt(colorPoint2Delta.x * colorPoint2Delta.x + colorPoint2Delta.y * colorPoint2Delta.y)) * colorIntensity2);
      float color2Amount = colorPoint2Dist;


      float totalColorAmount = max(0.001, saturateValue(color1Amount) + saturateValue(color2Amount));
      float blendedColorAmount = saturateValue(color2Amount) / totalColorAmount;

      vec3 blendedColor = mix(color1, color2, blendedColorAmount);

      float blendedOffAmount = min(1.0, totalColorAmount);
      blendedColor = mix(vec3(1.0), blendedColor, blendedOffAmount);

      // blend color
      outputColor.rgb *= blendedColor;


      float noiseValue = rand(coordOrigin * 13.7 + time);
      noiseValue *= noiseValue;
      noiseValue = 1.0 - noiseValue * noiseIntensity; // 0.18
      outputColor.rgb *= noiseValue;

      // debug
      // gl_FragColor = vec4(coord.x, coord.y, 0.0, 1.0);

      outputColor.rgb += pow(outputColor.rgb, vec3(glowSharpness)) * glowIntensity;

      // outputColor.r = distortionAmount;

      gl_FragColor = outputColor;
      
  }
`

export { vertexShader, fragmentShader }
export default {}; // export default so gatsby doesn't complain for react components on npm: build