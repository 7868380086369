const RadialBlurShader = {

    uniforms: {

        "tDiffuse": { value: null },
        "blurDistance": { value: 0.2 },
        "blurIntensity": { value: 0.5 },
        "centerDarkening": { value: 0.0 },
        "centerDarkeningPower": { value: 3.0 },
        "originalIntensity": { value: 1.0 },
        "radialEdgeBrightnessReduction": { value: 0.8 },

    },

    vertexShader: [

        "varying vec2 vUv;",

        "void main() {",

        "	vUv = uv;",
        "	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",

        "}"

    ].join("\n"),

    fragmentShader: [

        "#include <common>",

        // distance to scale blurring (0 = no distance, 1 = half width)
        "uniform float blurDistance;",

        // blur additive amount (0 = no effect, 1 = 100% add)
        "uniform float blurIntensity;",

        // center darkening amount (0 = no darkenering in center, 1 = 100% black darkening in center)
        "uniform float centerDarkening;",
        "uniform float centerDarkeningPower;",

        // original non-blurred blend amount (0 = no originalValues, 1 = 100% original values)
        "uniform float originalIntensity;",

        // reduce brightness of radial blur edges to soften radial blur ends (0 = no blur edge brightness reduction, 1 = full brightness reduction at ends)
        "uniform float radialEdgeBrightnessReduction;",

        "uniform sampler2D tDiffuse;",

        "varying vec2 vUv;",

        "void main() {",

        "	vec4 outputValue = texture2D( tDiffuse, vUv );",

        "	outputValue.rgb *= originalIntensity;",

        "	vec3 radialBlurValue = vec3(0.0);",

        // add sample raidal blur inward and outward
        "	for( int sampleI = 0; sampleI < 64; sampleI ++ ) { ",

        "	    float progress = float(sampleI) / 64.0;",

        "	    vec2 scaledCoord = (vUv - 0.5) * (1.0 + blurDistance * (progress - 0.5)) + 0.5;",

        "	    float brightness = 1.0 - pow((progress - 0.5) * 2.0, 2.0) * radialEdgeBrightnessReduction;",

        "	    radialBlurValue += texture2D( tDiffuse, scaledCoord ).rgb * brightness;",

        "	} ",

        // add radial blur values
        "	outputValue.rgb += radialBlurValue.rgb / 64.0 * blurIntensity;",

        "	vec2 centerDelta = (vUv - 0.5);",
        "	float centerDist = max(0.0, 1.0 - pow(sqrt(centerDelta.x * centerDelta.x + centerDelta.y * centerDelta.y) * 2.0, centerDarkeningPower));",

        "	outputValue.rgb *= (1.0 - centerDist * centerDarkening);",

        "	gl_FragColor =  outputValue;",

        "}"

    ].join("\n")

};

export { RadialBlurShader };
export default RadialBlurShader;