import { gsap, Power2 } from 'gsap'
import { useEffect } from 'react'

export const useTransition = (ref, { selectors, isReady, isLoading }) => {
  const { bg, logo, labelLoading, labelReady, audioIcon } = selectors
  const isVisible = !isReady

  useEffect(() => {
    const $root = ref.current
    const $bg = $root.querySelector(bg)
    const $logo = $root.querySelector(logo)
    const $labelLoading = $root.querySelector(labelLoading)
    const $labelReady = $root.querySelector(labelReady)
    const $audioIcon = $root.querySelector(audioIcon)

    let visiblityTl

    // Handle visibility
    if (isVisible) {
      visiblityTl = gsap.timeline({ defaults: { overwrite: true } })
      visiblityTl.to($bg, { opacity: 1 }, 0)
      visiblityTl.to(
        $logo,
        {
          opacity: 1,
          duration: 1.5,
          ease: Power2.easeOut,
        },
        0.25
      )
    } else {
      visiblityTl = gsap.timeline({ defaults: { overwrite: true } })
      visiblityTl.to($bg, { opacity: 0 }, 0)
      visiblityTl.to($logo, { opacity: 0, duration: 0.75 }, 0)
    }

    // Handle Loading
    let loadingTl
    if (isVisible && isLoading) {
      loadingTl = gsap.timeline()
      loadingTl.to(
        $labelLoading,
        {
          opacity: 1,
          duration: 0.5,
        },
        0.5
      )
    } else {
      loadingTl = gsap.timeline()
      loadingTl.to(
        $labelLoading,
        {
          opacity: 0,
          duration: 0.3,
        },
        0
      )
    }

    // Handle ready
    let readyTl
    if (isVisible && !isLoading) {
      readyTl = gsap.timeline()
      readyTl.to(
        [$labelReady, $audioIcon],
        {
          opacity: 1,
          duration: 0.5,
        },
        0.5
      )
    } else {
      readyTl = gsap.timeline()
      readyTl.to(
        [$labelReady, $audioIcon],
        {
          opacity: 0,
          duration: 0.3,
        },
        0
      )
    }

    return () => {
      visiblityTl.kill()
      loadingTl.kill()
      readyTl.kill()
    }
  }, [ref, bg, logo, labelLoading, labelReady, isVisible, audioIcon, isLoading])
}
