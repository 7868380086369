import { atom } from 'recoil'

/**
 * Manage the state of the default (ie homepage) music theme
 *
 * @usage
 * const isDefaultMusicPlaying = useRecoilValue(defaultMusicState)
 */

export const defaultMusicState = atom({
  key: 'defaultMusicState', // unique ID (with respect to other atoms/selectors)
  default: false,
})
