import { useStaticQuery, graphql } from 'gatsby'
import { get } from '@/utils/get'
import { getLink } from '@/storyblok/utils/getLink'
import faviconImg from '@/assets/favicon.png'
import faviconDarkmodeImg from '@/assets/favicon-darkmode.png'
import { getRichText } from '@/storyblok/utils/getRichText'
// import { getBackgroundColors } from '@/storyblok/utils/getBackgroundColors'
// import { getSlug } from '@/utils/getSlug'

/**
 * Pull in Storyblok config and fallback to Gatsby config
 */

export const useSiteConfig = () => {
  const data = useStaticQuery(
    graphql`
      query {
        gatsbyMetadata: site {
          siteMetadata {
            title
            subtitle
            description
            author
            siteUrl
            image
          }
        }
        global: storyblokEntry(slug: { eq: "global" }) {
          id
          full_slug
          content
        }
      }
    `
  )

  const gatsbyMetadata = data.gatsbyMetadata.siteMetadata || {}
  const storyblokGlobals = data.global ? JSON.parse(data.global.content) : {}

  const navPrimary = []
  storyblokGlobals.nav_primary.forEach((item) => {
    navPrimary.push(
      getLink({
        uid: item._uid,
        title: item.title,
        link: item.link,
        target: item.target,
      })
    )
  })

  // const navSecondary = []
  // storyblokGlobals.nav_secondary.forEach((item) => {
  //   navSecondary.push(
  //     getLink({
  //       uid: item._uid,
  //       title: item.title,
  //       link: item.link,
  //       target: item.target,
  //     })
  //   )
  // })

  const siteTitle = storyblokGlobals.title || gatsbyMetadata.title

  const projectScrollSounds = []
  storyblokGlobals.sound_projects_scroll.forEach(({ asset }) => {
    if (asset?.filename) projectScrollSounds.push(asset.filename)
  })

  const audio = {
    defaultMusic: storyblokGlobals.music_default?.filename,
    navHover: storyblokGlobals.sound_nav_hover?.filename,
    introEnter: storyblokGlobals.sound_intro_enter?.filename,
    projectMusic: storyblokGlobals.music_project?.filename,
    projectEnter: storyblokGlobals.sound_projects_enter?.filename,
    projectExit: storyblokGlobals.sound_projects_exit?.filename,
    projectScroll: projectScrollSounds,
    projectHover: storyblokGlobals.sound_projects_hover?.filename,
  }

  const audioPreload = []
  Object.keys(audio).forEach((key) => {
    const file = audio[key]

    if (typeof file === 'string' && file !== '') {
      audioPreload.push(file)
    }
    if (Array.isArray(file)) {
      file.forEach((childFile) => {
        if (typeof childFile === 'string' && childFile !== '') {
          audioPreload.push(childFile)
        }
      })
    }
  })

  const socialLinks = []
  storyblokGlobals.social_links.forEach((item) => {
    socialLinks.push(
      getLink({
        uid: item._uid,
        title: item.title,
        link: item.link,
        target: item.target,
      })
    )
  })

  // Get background colors from projects
  // const bgColors = []
  // const homeContent = data.home ? JSON.parse(data.home.content) : {}
  // homeContent.projects?.forEach(({ content }) => {
  //   bgColors.push(getBackgroundColors([content.color_01, content.color_02]))
  // })

  const typeformId = storyblokGlobals.typeform_id

  return {
    metadata: {
      title: siteTitle,
      description: storyblokGlobals.description || gatsbyMetadata.description,
      subtitle: storyblokGlobals.subtitle || gatsbyMetadata.subtitle,
      image: get(storyblokGlobals, 'image.filename') || gatsbyMetadata.image,
      siteUrl: gatsbyMetadata.siteUrl,
    },
    favicon: {
      default: faviconImg,
      darkMode: faviconDarkmodeImg || faviconImg,
    },
    header: {
      navPrimary,
      // navSecondary,
      title: siteTitle,
      socialLabel: storyblokGlobals.social_heading,
      socialLinks,
      hoverSound: audio.navHover,
      typeformId,
    },
    menu: {
      nav: navPrimary, //[...navPrimary, ...navSecondary],
      typeformId,
    },
    // background: {
    //   colors: bgColors,
    // },
    intro: {
      heading: storyblokGlobals.intro_heading,
      readyText: getRichText(storyblokGlobals.intro_description),
      enterSound: audio.introEnter,
      preloadAudioFiles: audioPreload,
    },
    footer: {},
    music: {
      defaultSrc: audio.defaultMusic,
      projectSrc: audio.projectMusic,
    },
    audio,
  }
}
