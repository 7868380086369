import React from 'react'
import { useAnimateProps } from 'react-animate-props'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { useRecoilValue } from 'recoil'
import { mutedState } from '@/store'

const Music = ({ fadeDuration, url, isPlaying, loop, volume }) => {
  const isMuted = useRecoilValue(mutedState)

  const animatedVolume = useAnimateProps(volume, {
    duration: fadeDuration,
    onAnimateProgress: (value) => {
      return Math.round((value + Number.EPSILON) * 10) / 10
    },
  })

  return (
    <ReactPlayer
      url={url}
      playing={isPlaying}
      loop={loop}
      volume={animatedVolume}
      muted={isMuted}
      controls={false}
      style={{ opacity: 0 }}
      width={0}
      height={0}
    />
  )
}

Music.defaultProps = {
  fadeDuration: 1500,
  volume: 0,
  loop: true,
  isPlaying: false,
}

Music.propTypes = {
  fadeDuration: PropTypes.number,
  url: PropTypes.string,
  volume: PropTypes.number,
  loop: PropTypes.bool,
  isPlaying: PropTypes.bool,
}

export { Music }
