import { atom } from 'recoil'

/**
 * Store browser state here, update in ResizeService
 *
 * @usage
 * const [projectIndex, setProjectIndex] = useRecoilState(projectsState)
 */

export const projectsState = atom({
  key: 'projectsState', // unique ID (with respect to other atoms/selectors)
  default: 0,
})
