export function sinBlend(value: number): number {
  return 0.5 - 0.5 * Math.cos(value * Math.PI);
}

export function lerp(valueA: any, valueB: any, amount: number): any {
  let delta: any = {};
  delta = valueB - valueA;
  delta = valueA + delta * amount;
  return delta;
}

export function clamp(value: number, min: number, max: number): number {
  return Math.max(min, Math.min(max, value));
}