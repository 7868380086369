// extracted by mini-css-extract-plugin
export var Menu = "G_bX";
export var aniSpin = "G_d";
export var bg = "G_5";
export var closeBg = "G_bY";
export var container = "G_bw";
export var content = "G_bx";
export var info = "G_b0";
export var info__cta = "G_b2";
export var info__cta__link = "G_b3";
export var info__text = "G_b1";
export var nav = "G_bH";
export var panel = "G_bW";
export var social = "G_bP";
export var toggle = "G_bZ";